import React, {Fragment, useEffect, useState} from "react";
import * as _ from "underscore";
import basket_icon from "../../../images/basket_icon_widget.svg";
import dog from "../../../images/dog_no_orders.svg";
import styled from "styled-components";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {LIST_USERS_STOP_ORDERS, MAIN_COLOR, IS_51_OBED} from "../../../utils/constants";
import {connect, useDispatch, useSelector} from "react-redux";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import {getSum} from "../../../actions/MenuActions";
import cartBack from "../../../images/cart-back.svg";
import delBasket from "../../../images/cart-clean.svg"
import cartEmpty from "../../../images/cart-empty.svg";
import {NavLink, withRouter} from "react-router-dom";
import deliveryMan from "../../../images/delivery-man.svg";
import GetCheckModal from "../GetCheckNew";

const Basket = (props) => {
    const [checkLimitRestaraunts, setLimitRestaraunts] = useState({});

    const createdOrder = useSelector(store => store.menu.createdOrder)
    const sum = useSelector(store => store.menu.sum)
    const dispatch = useDispatch();

    useEffect(() => {
        setLimitRestaraunts(() => {
            let result = {}
            _.map(_.groupBy(props.orders, "restId"), (groupItem, keyItem) => {
                let reducePrice = _.reduce(groupItem, (memo, num) => {
                    return  _.size(num.modifiers) === 0 ? (parseInt(num.price) * parseInt(num.amount)) + memo : (parseInt(num.price) * parseInt(num.amount)) + (parseInt(num.modifiers[0].price) * parseInt(num.amount)) + memo
                }, 0);

                let minOrderPrice = keyItem !== '152005' ? props.restarauntsInfo[keyItem].minOrderPrice : "1.00";

                result[keyItem] = reducePrice >= minOrderPrice
            })
            return result
        });
    }, [props.orders]);

    useEffect(() => {
        if (createdOrder && createdOrder.priceResult) {
            dispatch(getSum(sum - createdOrder.priceResult))
        }
    }, [createdOrder])

    const onSaveOrder = () => {
        props.changeWidget('check');
        props.saveOrders(props.day, props.orders, 'save', false, null, props.activePeriod, true, props.profile.phone);
        setTimeout(() => {
            props.getOrders([]);
        }, 1000)
    };

    const getCheck = (owner) => {
        let orders = props.orders.filter(order => order.restId === owner)
        props.getCheck(props.day, owner, props.activeCompany.id, props.activePeriod, orders)
    }

    const formatPeriod = (currentPeriod) => {
        let periodArr = []
        if (props.periods) {
            if (props.periods.length > 0) {
                periodArr = _.find(props.periods, period => {
                    return period[0] === currentPeriod
                })
            }
        }
        return 'Доставка c ' + periodArr[1] + ' до ' + periodArr[2]
    }

    return (
        <BasketWrapper widget={props.basket} id="basket">
            <BasketPanelUser>
                <BasketHeader
                    open={props.openBasket}
                >
                    {props.isMobile ? (
                        <>
                            <HeadLeft>
                                <NavClose onClick={props.closeCart}>
                                    <img src={cartBack} alt="Закрыть"/>
                                </NavClose>
                            </HeadLeft>
                            <HeadCenter>
                                <BasketTitle>Корзина</BasketTitle>
                            </HeadCenter>
                            <HeadRight>
                                {_.size(props.orders) && (
                                    <DelBasket onClick={() => {props.deleteBasket()}}>
                                        <img
                                            src={delBasket}
                                            alt="Очистить"
                                        />
                                    </DelBasket>
                                )}
                            </HeadRight>
                        </>
                    ) : (
                        <>
                            <HeadLeft>
                                Корзина {_.size(props.orders) > 0 ? '(' + _.size(props.orders) + ')' : false}
                            </HeadLeft>
                            <HeadCenter>
                                <BasketIcon src={basket_icon}/>
                            </HeadCenter>
                            <HeadRight onClick={() => {
                                props.deleteBasket()
                            }}>
                                Очистить
                            </HeadRight>
                        </>
                    )}
                </BasketHeader>
                {_.size(props.orders) !== 0 ? (
                    <BasketScroll>
                        <BasketBody>
                            {!props.isMobile && (
                                <Result>
                                    <ResultNameAll>Сумма заказа:</ResultNameAll>
                                    <ResultPriceAll>{props.sum} ₽</ResultPriceAll>
                                </Result>
                            )}
                            {_.map(_.groupBy(props.orders, 'restId'), (filterItem, key) => {
                                return <Fragment key={key}>
                                    <Fragment>
                                        <RestInfo>
                                            <RestImage src={props.restarauntsInfo[key].logo}/>
                                            <RestName>{props.restarauntsInfo[key].name}</RestName>
                                        </RestInfo>
                                        {_.reduce(_.filter(props.orders, (filterItem2) => {
                                            return filterItem2.restId === key
                                        }), (memo, num) => {
                                            return  _.size(num.modifiers) === 0 ? (parseInt(num.price) * parseInt(num.amount)) + memo : (parseInt(num.price) * parseInt(num.amount)) + (parseInt(num.modifiers[0].price) * parseInt(num.amount)) + memo
                                        }, 0) < props.restarauntsInfo[key].minOrderPrice && (
                                            <RestLimit>
                                                {props.isMobile ?
                                                    `Мин. сумма заказа ${props.restarauntsInfo[key].minOrderPrice} ₽` :
                                                    `Минимальная сумма заказа: ${props.restarauntsInfo[key].minOrderPrice} ₽`}
                                            </RestLimit>
                                        )}
                                    </Fragment>
                                    <WrapperTransitionGroup key={key}>
                                        {_.map(_.filter(props.orders, (filterItem2) => {
                                            return filterItem2.restId === key
                                        }), (order, key) => {
                                            let countRemainProduct = 0;
                                            let countDeltaProduct = order.amountForOrder - order.amountOrdered;
                                            let price = _.size(order.modifiers) === 0
                                                ? parseInt(order.price)
                                                : parseInt(order.modifiers[0].price) + parseInt(order.price);

                                            _.map(props.orders, (item_id) => {
                                                if (order.id === item_id.id) {
                                                    countRemainProduct += item_id.amount;
                                                }
                                            });

                                            let heightBlock = document.getElementById(key + 1 + "_order_id") ? document.getElementById(key + 1 + "_order_id").getBoundingClientRect().height : 'false';
                                            return (
                                                <CSSTransition classNames="option2" timeout={500}
                                                               key={_.size(order.modifiers) === 0 ? order.id : parseInt(order.modifiers[0].id) + parseInt(order.id) + key}
                                                               unmountOnExit>
                                                    {() => <Order id={key + 1 + "_order_id"} height={heightBlock}>
                                                        <OrderName>
                                                            <Name>
                                                                <span>{order.name}</span>
                                                                {_.size(order.modifiers) !== 0 && (
                                                                    <span> + {order.modifiers[0].name.replace(/[&\/\#,+()$~%.'":*?<>{}]/g, '')}</span>
                                                                )}
                                                            </Name>
                                                            <PanelControlOrder>
                                                                {props.isMobile ? (
                                                                    <CountOrder>
                                                                        <ItemListClickArea onClick={() => {
                                                                            props.removeFromCart(order, order.amount === 1)
                                                                        }}>
                                                                            <ItemListBtn>
                                                                                <div style={{
                                                                                    width: 12,
                                                                                    height: 2,
                                                                                    borderRadius: 1,
                                                                                    backgroundColor: '#263238',
                                                                                }}/>
                                                                            </ItemListBtn>
                                                                        </ItemListClickArea>
                                                                        <Count>{_.size(order.modifiers) === 0 ? order.amount : order.amount}</Count>
                                                                        {parseInt(countDeltaProduct - countRemainProduct) === 0 ? (
                                                                            <ItemListClickArea>
                                                                                <ItemListBtn count={true}>
                                                                                    <div style={{
                                                                                        width: 12,
                                                                                        height: 2,
                                                                                        borderRadius: 1,
                                                                                        backgroundColor: '#263238',
                                                                                    }}/>
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        width: 12,
                                                                                        height: 2,
                                                                                        borderRadius: 1,
                                                                                        backgroundColor: '#263238',
                                                                                        transform: 'rotate(90deg)',
                                                                                    }}/>
                                                                                </ItemListBtn>
                                                                            </ItemListClickArea>
                                                                        ) : (
                                                                            <ItemListClickArea onClick={() => {
                                                                                props.addToCart(order, false)
                                                                            }}>
                                                                                <ItemListBtn>
                                                                                    <div style={{
                                                                                        width: 12,
                                                                                        height: 2,
                                                                                        borderRadius: 1,
                                                                                        backgroundColor: '#263238',
                                                                                    }}/>
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        width: 12,
                                                                                        height: 2,
                                                                                        borderRadius: 1,
                                                                                        backgroundColor: '#263238',
                                                                                        transform: 'rotate(90deg)',
                                                                                    }}/>
                                                                                </ItemListBtn>
                                                                            </ItemListClickArea>
                                                                        )}
                                                                    </CountOrder>
                                                                ) : (
                                                                    <CountOrder>
                                                                        <ItemListBtn onClick={() => {
                                                                            props.removeFromCart(order, order.amount === 1)
                                                                        }}>-</ItemListBtn>
                                                                        <Count>{_.size(order.modifiers) === 0 ? order.amount : order.amount}</Count>
                                                                        {parseInt(countDeltaProduct - countRemainProduct) === 0 ? (
                                                                            <ItemListBtn count={true}>+</ItemListBtn>
                                                                        ) : (
                                                                            <ItemListBtn onClick={() => {
                                                                                props.addToCart(order, false)
                                                                            }}>+</ItemListBtn>
                                                                        )}
                                                                    </CountOrder>
                                                                )}
                                                            </PanelControlOrder>
                                                            {parseInt(countDeltaProduct - countRemainProduct) === 0 && (
                                                                <MessageNullCount>Закончилось</MessageNullCount>
                                                            )}
                                                        </OrderName>
                                                        <OrderPrice>{price} ₽</OrderPrice>
                                                        {!props.isMobile && (
                                                            <OrderDelete onClick={() => {
                                                                props.deleteToCart(order)
                                                            }}>
                                                                <CloseFontAwesome className="fas fa-times"/>
                                                            </OrderDelete>
                                                        )}
                                                    </Order>}
                                                </CSSTransition>
                                            )
                                        })}
                                        {checkLimitRestaraunts[key] ? (
                                            <ButtonOrder onClick={() => {
                                                getCheck(key)
                                            }}>Заказать</ButtonOrder>
                                        ) : (
                                            <ButtonOrder disable={true} onClick={() => {
                                                dispatch(deliveryCycleInfoMessage("error_limit_save_order", null, 5000))
                                            }}>Заказать</ButtonOrder>
                                        )}
                                    </WrapperTransitionGroup>
                                </Fragment>
                            })}
                            {props.isMobile && (
                                <Fragment>
                                    <DeliveryBox>
                                        <DeliveryLeft>
                                            <img src={deliveryMan} alt="Доставка"/>
                                            <DeliveryPeriodText>{formatPeriod(props.activePeriod)}</DeliveryPeriodText>
                                        </DeliveryLeft>
                                        <DeliveryPrice>{Math.round(props.companyOrder.deliveryPrice || 0)} ₽</DeliveryPrice>
                                    </DeliveryBox>
                                </Fragment>
                            )}
                        </BasketBody>
                        <BasketFooter openBasket={props.openBasket}>
                            {props.isMobile && (
                                <Result>
                                    <ResultNameAll>Сумма заказа:</ResultNameAll>
                                    <ResultPriceAll>{props.sum} ₽</ResultPriceAll>
                                </Result>
                            )}

                            {/*{_.every(checkLimitRestaraunts, bool => {*/}
                            {/*    return bool*/}
                            {/*}) ? (*/}
                            {/*    <ButtonOrder onClick={() => {*/}
                            {/*        onSaveOrder()*/}
                            {/*    }}>Заказать</ButtonOrder>*/}
                            {/*) : (*/}
                            {/*    <ButtonOrder onClick={() => {*/}
                            {/*        dispatch(deliveryCycleInfoMessage("error_limit_save_order", null, 5000))*/}
                            {/*    }}>Заказать</ButtonOrder>*/}
                            {/*)}*/}
                        </BasketFooter>
                    </BasketScroll>
                ) : (
                    <ImageNoOrders>
                        <Image src={props.isMobile ? cartEmpty : dog}/>
                        {props.isMobile ? (
                            <Fragment>
                                <MobileNoOrdersTitle>Здесь пока ничего нет</MobileNoOrdersTitle>
                                <MobileNoOrders>Но в меню много всего вкусного</MobileNoOrders>
                                <NavLink to="/" onClick={props.closeCart}>
                                    <BackToMenu>В меню</BackToMenu>
                                </NavLink>
                            </Fragment>
                        ) : (
                            <MessageNoOrders>Ой, пусто!</MessageNoOrders>
                        )}
                    </ImageNoOrders>
                )}
            </BasketPanelUser>

            <GetCheckModal
                isMobile={props.isMobile}
                checkData={props.checkData}
                periods={props.periods}
                getCheck={props.getCheck}
                checkLoaded={props.checkLoaded}
            />
        </BasketWrapper>
    )
};

export default withRouter(Basket);

const BasketPanelUser = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const ResultName = styled.div`
  width: 50%;
`;
const ResultNameAll = styled(ResultName)`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
const Image = styled.img`
  width: 100%;
  border-radius: 5px;
  
  @media(max-width: 992px){
    width: 55.77vw;
    border-radius: 0;
  }
`;
const MessageNoOrders = styled.p`
  margin: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 19px;
  color: #333333;
  padding-top: 40px;
  white-space: nowrap;
`;
const ImageNoOrders = styled.div`
  padding: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media(max-width: 992px){
    padding: 0;
    margin: 0 auto;
    margin-top: calc((100vh - 474px) / 2);
  }
`;
const OrderName = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  
  @media(max-width: 992px){
    padding-left: 0;
  }
`;
const OrderPrice = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
  
  @media(max-width: 992px){
    align-items: flex-start;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #263238;
  }
`;
const ResultPrice = styled(OrderPrice)`
  width: 50%;
`;
const ResultPriceAll = styled(ResultPrice)`
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    
    @media (max-width: 992px) {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 19px;
        line-height: 22px;
        color: #222222;
        width: auto;
    }
`;
const OrderDelete = styled.div`
  position: absolute;
  top: 0px;
  right: -5px;
  color: rgba(51,51,51,0.71);
  opacity: 0;
  transition: all .3s linear;
  cursor: pointer;
`;
const Order = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
  position:relative;
  
  &:last-of-type{
      /*border-bottom: 2px dotted rgba(34, 34, 34, 0.25);*/
      padding-bottom: 15px;
  }
  
  &.option2-enter {
    opacity: 0;
    transform: scale(0.9) translate(0, ${({height}) => height ? height + "px" : "50px"};);
  }

  &.option2-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.option2-exit {
    opacity: 1;
  }

  &.option2-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(0, -100%);
    margin-top: ${({height}) => height ? "-" + height + "px" : "50px"};
    transition: all 300ms linear;
  }
  
  &:hover ${OrderDelete}{
     opacity: 1;
  }
  
  @media(max-width: 992px){
     padding-top: 0;
     
     &:first-of-type{
         padding-top: 6px;
     }
     
     &:last-of-type{
         border-bottom: 1px solid #E6E9F0;
         padding-bottom: 7px;
     }
  }
`;
const Result = styled(Order)`
    margin: 5px 0;
    padding: 0;
    
    @media (max-width: 992px) {
        height: 100%;
        align-items: center;
        margin: 0;
        padding: 0 12px;
    }
`;
const Name = styled.p`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  
  @media(max-width: 992px){
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin-bottom: 0;
  }
`;
const PanelControlOrder = styled.div``;
const CountOrder = styled.div`
    text-align: center;
    margin: 5px 0;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    @media(max-width: 992px){
        margin: 0 0 4px -8px;
    }
`;
const Count = styled.div`
    display: flex;
    margin: 0 7px;
    font-weight: normal;
    font-size: 13px;
    
    @media(max-width: 992px){
        justify-content: center;
        /*width: 38.5px;*/
        width: 22.5px;
        margin: 0;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #263238;
    }
`;
const ItemListBtn = styled.span`
	height: 24px;
	width: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	font-size: 25px;
	user-select: none;
    background: #fff;
    border: ${({count}) => !count ? IS_51_OBED ? '1px solid #32CD32' : '1px solid #EF5350' : '1px solid #E0D5D2'};
    color: ${({count}) => !count ? IS_51_OBED ? '#32CD32' : '#EF5350' : '#E0D5D2'};
    cursor: ${({count}) => !count ? 'pointer' : 'not-allowed'};

	&:hover {
        border: ${({count}) => !count ? IS_51_OBED ? '1px solid #32CD32' : '1px solid #EF5350' : '1px solid #E0D5D2'};
        color: ${({count}) => !count ? '#fff' : '#E0D5D2'};
        background: ${({count}) => !count ? MAIN_COLOR : "transparent"};
	}
	
	@media(max-width: 992px){
	    position: relative;
		width: 28px;
        height: 28px;
        background: #F1EDEC;
        border: 0;
        color: #263238;
        
        &:hover {
            border: 0;
            color: #263238;
            background: #F1EDEC;
        }
    }
`;

const ItemListClickArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
`;

const ButtonOrder = styled.div`
    background: ${({disable}) => disable ? "#dddddd" : MAIN_COLOR};
    border-radius: 66px;
    width: 132px;
    height: 36px;
    color: ${({disable}) => disable ? "#999999" : "#ffffff"};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0 20px auto;
    cursor: ${({disable}) => disable ? "not-allowed" : "pointer"};
    
    @media (max-width: 992px) {
        
    }
`;

const BasketWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transform: ${({widget}) => widget ? 'rotateY(0deg)' : 'rotateY(180deg)'};
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    transition: .3s linear;
    z-index: ${({widget}) => widget ? '2' : '1'};
    flex-direction: column;
    opacity: ${({widget}) => widget ? '1' : '0'};
`;
const BasketHeader = styled.div`
    height: 50px;
    background: ${MAIN_COLOR};
    display: flex;
    justify-content: space-between;
    z-index: 2;
    border-radius: 6px 6px 0 0;
    
    @media(max-width: 992px){
        background: #ffffff;
        border-radius: 0;
    }
`;
const BasketScroll = styled.div`
    position: absolute;
    overflow-y: auto;
    height: calc(100% - 50px);
    width: 100%;

    @media (min-width: 992.01px) {
        &::-webkit-scrollbar {
            width: 8px;
        }
        
        &::-webkit-scrollbar-track {
            background: #f7efef;
            border-radius: 5px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #a5a2a2;
            border-radius: 5px;
        }
    }

    @media (max-width: 992px) {
        height: calc(100% - 110px); 
    }
`;
const BasketBody = styled.div`
    flex-grow: 1;
    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media (max-width: 992px) {
        padding: 0 12px 12px 12px;
    }
`;
const RestInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 0 0;
  
  @media(max-width: 992px){
    padding: 6px 0 0;
  }
`;
const RestLimit = styled.div`
  background: #FF1744;
  padding: 3px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border-radius: 66px;
  margin: 7px 0;
  
  @media(max-width: 992px){
      background: #CDDC39;
      padding: 2px 11px 3px;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      line-height: 15px;
      color: #263238;
      margin: 4px 0 9px;
  }
`;
const RestImage = styled.img`
  width: 32px;
  
  @media(max-width: 992px){
    width: 26px;
    height: 26px;
  }
`;
const RestName = styled.span`
  color: #212121;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 500
  margin-left: 15px;
  
  @media(max-width: 992px){
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin-left: 4px;
  }
`;
const BasketFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #fff;
    transition: ${({openBasket}) => !openBasket ? 'opacity .1s cubic-bezier(0.56, 0.01, 0.26, 0.26)' : 'opacity .7s cubic-bezier(0.56, 0.01, 0.26, 0.26)'};
    z-index: ${({openBasket}) => openBasket ? '1' : '-1'};
    opacity: ${({openBasket}) => openBasket ? '1' : '0'};
    padding: 0 15px;
    
    @media(max-width: 992px){
        height: 60px;
        padding: 0;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    }
`;
const HeadCenter = styled.div`
    z-index: 1;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const BasketIcon = styled.img`
    
`;
const SvgImage = styled.svg`
  
  color: #fff;
  stroke: #fff;
  fill: #fff;
  
  @media(max-width: 992px){
      transform: ${({openBasket}) => openBasket ? 'rotateX(180deg)' : 'rotateX(0deg)'};
  }
`;
const BottomIcon = styled.div`
  padding-left: 10px;
`;
const HeadLeft = styled.div`
    color: #fff;
    font-family: 'Roboto', sans-serif;
    /*font-weight: bold;*/
    font-size: 16px;
    line-height: 19px;
    display: flex;
    width: 40%;
    padding: 0 14px;
    z-index: 1;
    align-items: center;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    
    @media(max-width: 992px){
      padding: 0 8.72px 0 4.5px;
    }
`;
const HeadRight = styled(HeadLeft)`
    /*font-weight: normal;*/
    justify-content: flex-end;
    
    @media(max-width: 992px){
      padding: 0 8.72px 0 4.5px;
    }
`;
const MessageNullCount = styled.div`
    color: red;
    font-size: 10px;
    opacity: 1;
    bottom: 4px;
`;
const CloseFontAwesome = styled.i`

`;
const WrapperTransitionGroup = styled(TransitionGroup)`
    width: 100%;
    border-bottom: 2px dotted rgba(34, 34, 34, 0.25);
    
    @media(max-width: 992px){
      margin-bottom: 11px;
    }
`;

const NavClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
`;

const DelBasket = styled(NavClose)``;

const BasketTitle = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
    margin: 0;
`;

const MobileNoOrdersTitle = styled.p`
  margin: 26px 0 0 0;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  white-space: nowrap;
`;

const MobileNoOrders = styled.p`
  margin: 25px 0 0 0;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 15px;
  color: #333333;
  white-space: nowrap;
`;

const BackToMenu = styled.div`
    position: fixed;
    bottom: 44px;
    left: 0;
    right: 0;
    width: 182px;
    height: 42px;
    margin: 0 auto;
    background: ${MAIN_COLOR};
    border-radius: 21px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
`;

const DeliveryBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 4px 26px 0;
`

const DeliveryLeft = styled.div`
    display: flex;
`

const DeliveryPeriodText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 4px 0 0 9px;
`

const DeliveryPrice = styled.p`
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 4px 4px 0 0;
`
