import React, {Component, Fragment} from "react";
import styled from "styled-components";
import Modal from "react-modal";
import {NavLink} from "react-router-dom";

Modal.setAppElement('#root');

class ModalUniversal extends Component {
    constructor(props){
      super(props);

      this.state = {
          showModal: false,
          showAlertNoRemove: false,
      };

      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.actionMethod = this.actionMethod.bind(this);
    };

    handleOpenModal (e) {
        e.stopPropagation();

        this.setState({
            showModal: !this.state.showModal,
        });
    };

    actionMethod (e, param1 = undefined, param2 = undefined, param3 = undefined) {
        e.stopPropagation();
        let props = this.props;

        if (props.action) {
            props.action(param1, param2, param3);
            this.handleOpenModal(e);
        }
    }

    render() {
        const {showAlertNoRemove, showModal} = this.state;
        const {
            actionParam1,
            actionParam2,
            actionParam3,
            messageCancel,
            messageButton,
            buttonDate
        } = this.props;

        return(
            <Fragment>
                <WrapperButtonActive onClick={(e) => {this.handleOpenModal(e)}}>{buttonDate}</WrapperButtonActive>
                <Modal
                    closeTimeoutMS={100}
                    style={customStyles}
                    overlayClassName={'Overlay'}
                    isOpen={showModal}
                    onRequestClose={this.handleOpenModal}
                >
                    <ModalWrapper style={showAlertNoRemove ? {marginBottom: '0'} : {}}>
                        <ModalWrapperTitle>{this.props.message}</ModalWrapperTitle>
                        <ButtonsRemove>
                            {!this.props.routePage ? (
                                <ModalWrapperPay onClick={(e)=>{this.actionMethod(e, actionParam1, actionParam2, actionParam3)}}>
                                    {messageButton}
                                </ModalWrapperPay>
                            ) : (
                                <ModalWrapperPayHref to="/" onClick={(e)=>{this.actionMethod(e, actionParam1, actionParam2, actionParam3)}}>
                                    {messageButton}
                                </ModalWrapperPayHref>
                            )}
                            <ModalWrapperClose onClick={this.handleOpenModal}>{messageCancel}</ModalWrapperClose>
                        </ButtonsRemove>
                    </ModalWrapper>
                </Modal>
            </Fragment>
        );
    }
};

const customStyles = {
    content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "0",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "625px",
        overflow: "hidden",
    }
};

export default ModalUniversal;

const ModalWrapper = styled.div`
	padding: 25px 0;
	background: #fff;
	border-radius: 4px;
	position: relative;
	transition: .3s linear;
	
	@media(max-width:561px) and (min-width:490px){
	   margin-top: 96px;
	}
	
    @media(max-width:490px) and (min-width:330px){
	   margin-top: 118px;
	}
	
    @media(max-width:330px){
	   margin-top: 140px;
	}
`;
const ButtonsRemove = styled.div`
    display: flex;
    justify-content: space-around;
    
    @media(max-width: 526px){
        flex-direction: column;
        align-items: center;
    }
`;
const ModalWrapperTitle = styled.h1`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #263238;
    text-align: center;
`;
const Pay = styled.div`
    width: 200px;
    height: 52px;
    background-color: #E0D5D2;
    border: 1px solid #E0D5D2;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #fff;  
    position: relative;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    
    @media(max-width: 526px){
        margin-top: 10px;
    }
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -200px;
        width: 0;
        height: 252px;
        background: rgba(255,255,255,0.2);
        border-radius: 5px;
        -webkit-transition: all .6s ease;
        transition: all .3s ease;
        transform: rotate(210deg);
    }   
    
    &:hover{
        text-decoration: none;
    
        &:before {
            width: 1000%;
        }
    }
`;
const Remove = styled(Pay)`
    background: #E57373;
    border-radius: 6px;
    color: #fff;
    border: 1px solid #E57373;
    outline: none;
    margin-top: 40px;
    
    &:hover{
        &:before {
            width: 1000%;
        }
    }
`;
const RemoveDelete = styled.div`
    color: #fff;
    background: #EF5350;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;
    cursor: pointer;
`;
const ModalWrapperClose = styled(Pay)`
    border: 1px solid #333333;
    color: #333333;
    font-size: 16px;
    background: #fff;
    margin-top: 40px;
    height: 50px;
`;
const ModalWrapperPay = styled(Remove)`
    height: 50px;
`;
const ModalWrapperPayHref = styled(NavLink)`
    background: #E57373;
    border-radius: 6px;
    color: #fff;
    border: 1px solid #E57373;
    outline: none;
    margin-top: 40px;
    text-decoration: none;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
        text-decoration: none;
        color: #fff;
    
        &:before {
            width: 1000%;
        }
    }
`;
const WrapperButtonActive = styled.div`
    display: flex;
    align-items: center;
`;