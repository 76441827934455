import React from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Spinner from "../../../components/Spinner";
import pensiveFace from "../../../imagesForPromo/pensive-face.png";
import starStruck from "../../../imagesForPromo/star-struck.png";
import SearchBar from "./SearchBar";

const MessageOverlay = ({ status, clientAddress, onNext }) => {
    if (window.innerWidth <= 748) {
        return (
            <Wrapper>
                {status === 'pending' ?
                    <Card><Spinner/></Card>
                : status === 'rejected' ?
                    <Card
                        image={window.innerWidth > 360 && pensiveFace}
                        title='Очень жаль'
                        text='Извините, сюда нельзя сделать заказ'
                    >
                        <div style={{height: '16px'}}></div>
                        <SearchBar/>
                        <div style={{height: '16px'}}></div>
                        <Button type='primary' disabled onClick={() => onNext()}>Получить обед</Button>
                    </Card>
                : status === 'fulfilled' ?
                    <Card
                        image={window.innerWidth > 360 && starStruck}
                        title='Отлично! На этот адрес доставим'
                    >
                        <div style={{height: '16px'}}></div>
                        <SearchBar/>
                        <div style={{height: '16px'}}></div>
                        <Button type='primary' onClick={() => onNext()}>Получить обед</Button>
                    </Card>
                :
                <Card
                    title='Выберите свой адрес на карте в зоне доставки'
                >
                    <div style={{height: '16px'}}></div>
                    <SearchBar/>
                </Card>
                }
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            {status === 'pending' ?
                <Card><Spinner/></Card>
            : status === 'inCircle' ?
                <Card
                    image={pensiveFace}
                    title='Извините'
                    text='Данный адрес уже не может участвовать в акции'
                />
            : status === 'rejected' ?
                <Card
                    image={pensiveFace}
                    title='Очень жаль'
                    text='Извините, сюда нельзя сделать заказ'
                />
            : status === 'fulfilled' ?
                <Card
                    image={starStruck}
                    title='Отлично! На этот адрес доставим!'
                    text={<p>Адрес <b>{clientAddress}</b> подходит для доставки обеда</p>}
                    action={<Button type='primary' onClick={() => onNext()}>Получить обед</Button>}
                />
            :
                <Card><div style={{fontSize: '24px', fontWeight: '500', textAlign: 'center'}}>Выберите свой адрес<br/>на карте в зоне доставки</div></Card>
            }
        </Wrapper>
    )
}

export default MessageOverlay;

const Wrapper = styled.div`
    max-width: 748px;
    @media (max-width: 748px) {
        width: 100%;
    }
`
