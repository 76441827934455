import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import styled from "styled-components";
import MapZoomControl from "./MapZoomControl";
import MessageOverlay from "./MessageOverlay";

const Footer = ({ onNext }) => {
    const { geocodeAddress, geocodeLoading, isInsidePolygon, isHouse } = useSelector((state) => state.interactiveMapPromo.map);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (geocodeLoading !== null) {
            setStatus(
                geocodeLoading
                    ? 'pending'
                    : isInsidePolygon && isHouse
                    ? 'fulfilled'
                    : 'rejected'
            );
        }
    }, [geocodeLoading, isInsidePolygon, isHouse]);

    return (
        <Wrapper>
            <Space/>

            <MessageOverlay
                status={status}
                clientAddress={geocodeAddress}
                // clientAddress={
                //     `${addressGeocodeObject?.locality ? `${addressGeocodeObject.locality}, ` : ''}` +
                //     `${addressGeocodeObject?.street ? `${addressGeocodeObject.street}, ` : ''}` +
                //     `${addressGeocodeObject?.house ? `${addressGeocodeObject.house}` : ''}`
                // }
                onNext={onNext}
            />

            <MapZoomControl/>

        </Wrapper>
    )
}

export default Footer;

const Wrapper = styled.div`
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
    width: 100%;
    max-width: 90%;
    padding: 0 16px;
    user-select: none;
    pointer-events: none;

    @media (max-width: 748px) {
        bottom: 0;
        flex-direction: column-reverse;
        padding: 0;
        max-width: 100%;
    }
`;

const Space = styled.div`
    @media (max-width: 748px) {
        display: none;
    }
`;
