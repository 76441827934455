import React, {Component} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import payment from "../../images/payment.svg";
import apple from "../../images/apple-download-button.png";
import google from "../../images/google-download-button.png";
import {NAME_COMPANY, PHONE_COMPANY} from "../../utils/constants";

class Footer extends Component {
	render() {
		let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
		let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

		return (
			<Container id="footer">
				<Wrapper>
					<Top>
						<Nav>
							<NavCol>
								<NavItem>
									<NavTitle>{NAME_COMPANY}</NavTitle>
								</NavItem>
								<NavItem>
									<NavLink to="/about">О нас</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/">Меню</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/pay">Оплата</NavLink>
								</NavItem>
								<NavItem>
									<NavLink to="/delivery">Доставка</NavLink>
								</NavItem>
								{(!is51obed && !isStraus) && (
									<NavItem>
										<NavLink to="/manager">Компаниям</NavLink>
									</NavItem>
								)}
							</NavCol>
							{(!is51obed && !isStraus) && (
								<NavCol>
									<NavItem>
										<NavTitle>Соц. сети</NavTitle>
									</NavItem>
									<NavItem>
										<NavExtLink
											href="https://www.instagram.com/sitiy_tlt/"
											target="_blank">
											Instagram
										</NavExtLink>
									</NavItem>
									<NavItem>
										<NavExtLink href="https://vk.com/sytofis" target="_blank">
											Вконтакте
										</NavExtLink>
									</NavItem>
									<NavItem>
										<NavExtLink href="https://www.facebook.com/sitiyofiss/" target="_blank">
											Facebook
										</NavExtLink>
									</NavItem>
									<NavItem>
										<NavExtLink href="https://t.me/sitiy_ofis" target="_blank">
											Telegram
										</NavExtLink>
									</NavItem>
								</NavCol>
							)}
						</Nav>
						<Contacts>
							<ContactsPhone href={"tel:" + PHONE_COMPANY}>
								{PHONE_COMPANY}
							</ContactsPhone>
							<ContactsText>звонок бесплатный</ContactsText>
							<ContactsPayment src={payment} alt="Безналичная оплата" />
							{(!is51obed && !isStraus) && (
								<DownloadPhone>
									<a
										target="_blank"
										href="https://apps.apple.com/ru/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950"
										rel= "noopener noreferrer"
									>
										<ImageLabelPhone src={apple}/>
									</a>
									<a
										//target="_blank"
										//href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice"
										href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&utm_source=my_comp&utm_medium=cpa&utm_campaign=promo&utm_content=landing_page&utm_term=just"
										rel= "noopener noreferrer"
									>
										<ImageLabelPhone src={google}/>
									</a>
								</DownloadPhone>
							)}
						</Contacts>
					</Top>
					{is51obed ? (
						<Bottom>
							<Credentials>
								ООО «Шайба», ИНН: 6321107589, КПП: 632101001, Адрес: 445026, г. Тольятти, ул. Свердлова, 51
							</Credentials>
							<Copyright>Дом 51 © 2011-2022</Copyright>
							<a
								target="_blank"
								href="https://api.menuforme.online/files/146923/static/userpolicy.html?time=164396005052412"
								rel="noopener noreferrer"
								style={{textDecoration: 'none'}}
							>
								<Terms>Пользовательское соглашение</Terms>
							</a>
						</Bottom>
					) : (isStraus ? (
						<Bottom>
							<Copyright>ООО "Технология Снаб" © 2011-2022</Copyright>
							<a
								target="_blank"
								href="https://api.menuforme.online/files/146923/static/userpolicy.html?time=164396005052412"
								rel="noopener noreferrer"
								style={{textDecoration: 'none'}}
							>
								<Terms>Пользовательское соглашение</Terms>
							</a>
						</Bottom>
					) : (
						<Bottom>
							<Credentials>
								Общество с ограниченной ответственностью "Бизнес-Софт" 445143, Самарская область, г. Тольятти Южное Шоссе 161, корпус 2.1 ИНН: 6321264045 КПП: 632101001 ОГРН: 1116320004371
							</Credentials>
							<Copyright>Бизнес-Софт © 2011-2022</Copyright>
							<a
								target="_blank"
								href="https://api.menuforme.online/files/146923/static/userpolicy.html?time=164396005052412"
								rel="noopener noreferrer"
								style={{textDecoration: 'none'}}
							>
								<Terms>Пользовательское соглашение</Terms>
							</a>
						</Bottom>
					))}
				</Wrapper>
			</Container>
		);
	}
}

export default Footer;

const Container = styled.footer`
	background: #FFFBFB;
	@media(max-width:992px){
		margin-bottom: 65px;
	}
`;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 15px 10px;
	margin: 0 auto;
`;

const Top = styled.div`
	display: flex;

	@media (max-width: 550px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

const Nav = styled.nav`
	display: flex;

	@media (max-width: 868px) {
		display: none;
	}
`;

const NavCol = styled.ul`
	margin: 0 60px 0 0;
	padding: 0;
	list-style: none;
`;

const NavItem = styled.li`
	margin-bottom: 5px;
`;

const NavTitle = styled.span`
	font-size: 18px;
	color: #333333;
	font-weight: 700;
`;

const NavLink = styled(Link)`
	font-size: 16px;
	color: rgba(51, 51, 51, 0.5);
	transition: all 0.15s ease;

	&:hover {
		text-decoration: none;
		color: #bbb;
	}
`;

const NavExtLink = styled.a`
	font-size: 16px;
	color: rgba(51, 51, 51, 0.5);
	transition: all 0.15s ease;

	&:hover {
		text-decoration: none;
	color: #bbb;
	}
`;

const Contacts = styled.div`
	margin-left: auto;
	text-align: right;
	font-family: "Lato", sans-serif;

	@media (max-width: 550px) {
		margin: 0;
		text-align: center;
	}
`;

const ContactsPhone = styled.a`
	font-size: 32px;
	color: #333333;
	display: block;
	line-height: 1.2;
	transition: .2s ease;

	&:hover {
		text-shadow: 0 0 5px #fff;
		color: #5d5d5d;
		text-decoration: none;
	}
`;

const ContactsText = styled.span`
	font-size: 14px;
	color: rgba(51, 51, 51, 0.5);
	display: block;
	margin-bottom: 15px;
`;

const ContactsPayment = styled.img`
	height: 25px;
`;

const Bottom = styled.div`
	text-align: center;
`;

const Credentials = styled.p`
	font-size: 12px;
	font-family: "Lato", sans-serif;
	line-height: 1.3;
	color: rgba(51, 51, 51, 0.5);
	margin-bottom: 10px;
    margin-top: 15px;	
`;

const Copyright = styled.span`
	display: block;
	margin-bottom: 6px
	font-size: 14px;
	color: rgba(51, 51, 51, 0.5);
`;

const Terms = styled.span`
	display: block;
	font-size: 12px;
	color: rgba(51, 51, 51, 0.5);
	cursor: pointer;
`;

const DownloadPhone = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 17px;
`;

const ImageLabelPhone = styled.img`
	width: 100px;
	cursor: pointer;
`;
