import React from 'react';
import styled from 'styled-components';

const CitySelector = ({ cities, onSelect }) => {
    return (
        <Wrapper>
            <StyledTitle>Выберите свой город</StyledTitle>
            <CityList>
                {cities && cities.length > 0 && 
                    cities.map((city) => (
                        <CityItem key={city.id} onClick={() => onSelect(city)}>
                            {city.name}
                        </CityItem>
                    ))
                }
            </CityList>
        </Wrapper>
    );
};

export default CitySelector;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTitle = styled.div`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    color: #2A2A2A;

    @media (max-width: 748px) {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.11px;
    }
`;

const CityList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    height: 50vh;
    overflow-y: auto;
`;

const CityItem = styled.li`
    padding: 16px 0;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        color: #9AD087;
    }
    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
    @media (max-width: 748px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.41px;
    }
`;
