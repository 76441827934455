import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Order from "./Order";
import {nanoid}  from 'nanoid'
import {AnimatePresence, motion} from "framer-motion";

import arrow from '../../images/MyOrdersImg/arrow.svg'
import {request} from "../../utils/request";
import CancelModal from "./CancelModal";

const MyOrders = (props) => {

    const [ordersList, setOrdersList] = useState([])
    const [companyOrdersList, setCompanyOrdersList] = useState([])
    const [selectedOrder, setSelectedOrder] = useState( {} )
    const [orderIsSelect, setOrderIsSelect] = useState( false )

    //get orders from api
    useEffect(() => {
        getOrders().then(res => {
            console.log(res.data, 'res.data')
            setCompanyOrdersList(res.data.companyOrders)
            setOrdersList(res.data.orders)
        })
    }, [])

    useEffect(() => {
        if ( Object.values(selectedOrder).length ) {
            setOrderIsSelect(true)
        }
    }, [selectedOrder])

    //APIs
    const getOrders = async () => {
        return await request("AppDinnerController", "GetOrders1", {})
    }
    //state handlers
    const selectOrder = (id) => {
        //check on selected order in order component, in click handler
        setSelectedOrder(ordersList[id])
    }
    const deleteOrderFromOrderList = (id) => {
        const test = [...ordersList]
        test.splice(id, 1)

        setOrderIsSelect(false)
        setOrdersList([...test])
        setSelectedOrder( {} )
    }
    //content func
    const drawOrderList = (orderDataArray = [], sideProps = {}) => {
        //order data
        return orderDataArray.map((order, id) => {
            const {
                companyOrderId,
                company,
                period,
                priceResult,
                prices,
                isPayed,
                status,
                owner,
                orderExpDateValue,
                date,
                paysystem,
                products,
            } = order

            const shortOrderData = {companyOrderId, company, period, priceResult, prices, isPayed, status, orderExpDateValue}

            return <Order
                key={nanoid()}
                //short props pass
                {...{ownerId: owner.id, orderId: order.id, ...shortOrderData, ...sideProps, id, owner, date, paysystem, products}}
            />
        })
    }
    const drawOrderDetails = (orderData = {}, sideProps = {}) => {
        window.scroll(0, 0)
        const {
            companyOrderId,
            company,
            period,
            priceResult,
            prices,
            isPayed,
            owner,
            products,
            status,
            orderExpDateValue,
            date,
            paysystem,
        } = selectedOrder

        return <Order showMore={true} {...{
            companyOrderId,
            company,
            period,
            priceResult,
            prices,
            isPayed,
            owner,
            products,
            ownerId: owner.id,
            orderId: selectedOrder.id,
            status,
            orderExpDateValue,
            date,
            paysystem,
        }} {...sideProps}/>
    }

    const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    let cookieCheck = getCookie("settingAttachment");
    const cookieStyles = (cookieCheck === "no" || cookieCheck === undefined) && (document.documentElement.clientWidth < 768) ? {marginTop: 100} :  {}

    return (
        <MyOrdersWrapper style={cookieStyles}>
            <CancelModal {...{ deleteOrderFromOrderList }}/>
            <Container>
                <SectionHeader {...{orderIsSelect}}>
                    {
                        orderIsSelect
                            ? (
                                <>
                                    <span style={{
                                        opacity: 0.4,
                                        cursor: 'pointer',
                                        marginRight: 5
                                    }} onClick={() => {
                                        setSelectedOrder({})
                                        setOrderIsSelect(false)
                                    }}>Все заказы
                                    </span>
                                    <img style={{marginRight: 5}} src={arrow} alt=""/>
                                    <motion.div
                                        initial={{
                                            opacity: 0,
                                            x: -20
                                        }}
                                        animate={{
                                            opacity: 1,
                                            x: 0,
                                        }}
                                    >
                                        #{selectedOrder.companyOrderId}
                                    </motion.div>
                                </>
                            )
                            : 'Все заказы'
                    }
                </SectionHeader>
                {
                    ordersList.length
                        ?
                        orderIsSelect
                            ? drawOrderDetails(selectedOrder,{...{orderIsSelect}})
                            : drawOrderList(ordersList, {selectOrder})
                        : null
                }
            </Container>
        </MyOrdersWrapper>
    )
}

export default MyOrders;

const MyOrdersWrapper = styled.div`
  padding: 20px 0;
  height: 100%;
`
const SectionHeader = styled.h2`
  align-self: flex-start;
  padding: 58px 0 24px 0;
  
  @media(max-width: 768px) {
    /*padding-left: 10px;*/
  }
  
  ${({orderIsSelect}) => orderIsSelect && ({
    display: 'flex',
    alignItems: 'center',
  })}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  /*width: 700px;*/
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 30px;
  
  @media(max-width: 768px) {
    width: 100%;
  }
  
  > div:nth-child(n + 1) {
    margin-bottom: 55px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`
