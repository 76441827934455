import React, { useState } from 'react';
import styled from 'styled-components';
import ym from "react-yandex-metrika";
import Modal from '../../components/Modal';
import Header from './components/Header';
import MapContainer from './components/MapContainer';
import AuthForm from './components/AuthForm';
import Footer from './components/Footer';

const MapPage = () => {
    const [showAuthPopup, setShowAuthPopup] = useState(false);

    const handleClickNext = () => {
        setShowAuthPopup(true);
        ym('reachGoal','click_next_to_auth');
    }

    return (
        <Wrapper>
            <Header/>
            <MapContainer/>
            <Footer
                onNext={handleClickNext}
            />
            <Modal
                visible={showAuthPopup}
                onClose={setShowAuthPopup}
            >
                <AuthForm showAuthPopup={showAuthPopup}/>
            </Modal>
        </Wrapper>
    )
}

export default MapPage;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
    overflow: hidden;
`