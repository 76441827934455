import React, {Component} from "react";
import Tooltip from '@material-ui/core/Tooltip';
import styled from "styled-components";
import Modal from "react-modal";
import {MAIN_COLOR} from "../../../utils/constants";

Modal.setAppElement("#root");

class RestoranInfoModal extends Component {
    render() {
        const {
            key,
            rest,
            logo,
            modalRestInfoIsOpen,
            toggleModalRestInfo
        } = this.props;

        return (
            <Container key={key}>
                {/*Логотип ресторана на карточке товара*/}
                {/*<Tooltip title={rest.name}>*/}
                {/*    <LogoRest src={logo} onClick={(e)=>{toggleModalRestInfo(e)}}/>*/}
                {/*</Tooltip>*/}
                <Modal
                    closeTimeoutMS={100}
                    isOpen={modalRestInfoIsOpen}
                    style={customStyles}
                    overlayClassName={"Modifiers__Overlay"}
                    onRequestClose={(e)=>{toggleModalRestInfo(e)}}
                >
                    <ModalWrapper>
                        <Wrapper>
                            <ModalHeader>
                                <Logo src={logo}/>
                                <Title>{rest.name}</Title>
                            </ModalHeader>
                            <ModalBody>
                                {rest.description && (
                                    <Description>
                                        <TitleDescription>
                                            О ресторации «{rest.name}» в Тольятти
                                        </TitleDescription>
                                        <TextDescription>
                                            <span>{rest.description.substr(0, rest.description.search(/ИНН/))}</span>
                                            <span>{rest.description.substr(rest.description.search(/ИНН/), rest.description.search(/' '/))}</span>
                                            <span>{rest.description.substr(rest.description.search(/\n/))}</span>
                                        </TextDescription>
                                    </Description>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <CloseButton onClick={(e)=>{toggleModalRestInfo(e)}}>Продолжить</CloseButton>
                            </ModalFooter>
                        </Wrapper>
                    </ModalWrapper>
                </Modal>
            </Container>
        )
    }
}

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "25px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "550px",
    }
};

export default RestoranInfoModal;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 12px;
	display: flex;
	height: auto;
	overflow: hidden;
	padding: 25px;
	width: 100%;
	position: relative;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const ModalHeader = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
`;

const ModalBody = styled.div`
    padding: 10px 0 30px;
`;

const TitleDescription = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    padding-bottom: 15px;
`;

const TextDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

const Description = styled.div`
    padding: 10px 0 30px;
`;

const ModalFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const CloseButton = styled.div`
    width: 242px;
    height: 42px;
    background: ${MAIN_COLOR};
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Logo = styled.img`
    width: 70px;
    height: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin-right: 20px;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #263238;
`;

const LogoRest = styled.img`
	position: absolute;
	left: 5px;
	bottom: 5px;
	width: 40px;
	transition: .3s linear;
    border-radius: 6px;
	
	&:hover{
	    width: 52px;
        box-shadow: 0 0 17px 5px white;
	}
`;