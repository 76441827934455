import React, {Component, Fragment} from "react";
import styled from "styled-components";
import Modal from "react-modal";
import Tooltip from '@material-ui/core/Tooltip';
import {MAIN_COLOR} from "../../../utils/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import orderDelete from "../../../images/order-trash.svg";

Modal.setAppElement('#root');

class ModalDeleteAll extends Component {
    constructor(props){
      super(props);

      this.state = {
          showModal: false,
          showAlertNoRemove: false,
          purpose: '',
      };

      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.deleteOrderAll = this.deleteOrderAll.bind(this);
    };

    handleOpenModal (purpose) {
        this.setState({
            showModal: !this.state.showModal,
            purpose
        });
    };

    deleteOrderAll = () => {
        this.props.saveOrder(this.props.day, this.props.restaurants, 'delete', window.dataCity(), this.props.regOrders, this.props.activePeriod);
        this.props.getSum(0);
        this.props.getCheckEdit();
        this.handleOpenModal();
        this.props.getOrders([]);
    };

    handleOpenAlert () {
        this.setState({
            showAlertNoRemove: true
        });
    };

    render() {
        const {showAlertNoRemove, showModal} = this.state;

        const customStyles = {
            content: {
                top: "30%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                transform: "translate(-50%, -50%)",
                padding: "0",
                backgroundColor: "transparent",
                border: "none",
                cursor: "auto",
                width: "100%",
                maxWidth: "625px",
                overflow: "hidden",
            }
        };

        return(
            <Fragment>
                {this.props.isMobile ? (
                    <OrderDeleteButton onClick={() => {this.handleOpenModal('all')}}>
                        <img src={orderDelete} alt="Отменить"/>
                    </OrderDeleteButton>
                ) : (
                    <RemoveDelete
                        onClick={() => {this.handleOpenModal('all')}}
                        icon={faTrashAlt}
                    />
                )}
                <Modal
                    closeTimeoutMS={100}
                    style={customStyles}
                    overlayClassName={'Overlay'}
                    isOpen={showModal}
                    onRequestClose={this.handleOpenModal}
                >
                    <ModalWrapper style={showAlertNoRemove ? {marginBottom: '0'} : {}}>
                        <ModalWrapperTitle>Вы уверены что хотите удалить ваш заказ?</ModalWrapperTitle>
                        <ButtonsRemove>
                            {
                                this.props.payStatus === 2
                                    ? (
                                        <ModalWrapperPay
                                            onClick={() => { this.handleOpenAlert() }}
                                            style={
                                                showAlertNoRemove
                                                    ? {background: "#bebebe", borderColor: '#bebebe', opacity: '0.4'}
                                                    : {}
                                            }
                                        >
                                            Удалить заказ
                                        </ModalWrapperPay>
                                    ) : (
                                        <ModalWrapperPay
                                            onClick={this.deleteOrderAll}
                                        >
                                            Удалить заказ
                                        </ModalWrapperPay>
                                    )
                            }
                            <ModalWrapperClose onClick={this.handleOpenModal}>Назад</ModalWrapperClose>
                        </ButtonsRemove>
                    </ModalWrapper>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = store => ({
    regOrders: store.menu.registrationOrders,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalDeleteAll);

const ModalWrapper = styled.div`
	padding: 25px 0;
	background: #fff;
	border-radius: 4px;
	position: relative;
	transition: .3s linear;
	//margin-bottom: -74px;
	
	@media(max-width:561px) and (min-width:490px){
	   margin-top: 96px;
	}
	
    @media(max-width:490px) and (min-width:330px){
	   margin-top: 118px;
	}
	
    @media(max-width:330px){
	   margin-top: 140px;
	}
`;
const ButtonsRemove = styled.div`
    display: flex;
    justify-content: space-around;
    
    @media(max-width: 526px){
        flex-direction: column;
        align-items: center;
    }
`;
const ModalWrapperTitle = styled.h1`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #263238;
    text-align: center;
    
    @media(max-width: 992px){
        font-family: "Roboto", sans-serif;
    }
`;
const Pay = styled.div`
    width: 200px;
    height: 52px;
    background-color: #E0D5D2;
    border: 1px solid #E0D5D2;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #fff;  
    position: relative;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    
    @media(max-width: 526px){
        margin-top: 10px;
    }
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -200px;
        width: 0;
        height: 252px;
        background: rgba(255,255,255,0.2);
        border-radius: 5px;
        -webkit-transition: all .6s ease;
        transition: all .3s ease;
        transform: rotate(210deg);
    }   
    
    &:hover{
        text-decoration: none;
    
        &:before {
            width: 1000%;
        }
    }
`;
const Remove = styled(Pay)`
    background: #E57373;
    border-radius: 6px;
    color: #fff;
    border: 1px solid #E57373;
    outline: none;
    margin-top: 40px;
    
    &:hover{
        &:before {
            width: 1000%;
        }
    }
`;
const RemoveDelete = styled(FontAwesomeIcon)`
    color: ${MAIN_COLOR};
    font-size: 22px;
    margin-left: 20px;
`;
const ModalWrapperClose = styled(Pay)`
    border: 1px solid #333333;
    color: #333333;
    font-size: 16px;
    background: #fff;
    margin-top: 40px;
    height: 50px;
    
    @media(max-width: 992px){
        font-family: "Roboto", sans-serif;
    }
`;
const ModalWrapperPay = styled(Remove)`
    height: 50px;
    
    @media(max-width: 992px){
        font-family: "Roboto", sans-serif;
    }
`;
const TooltipDeleteOrder = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 4px 8px;
`;

const OrderDeleteButton = styled.div`
    width: 44px;
    height: 44px; 
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`