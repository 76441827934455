const validate = values => {
    const errors = {};

    // if (!values.name_company) {
    //     errors.name_company = 'Введите название компании'
    // }
    if (!values.street) {
        errors.street = 'Введите название улицы'
    }
    if (!values.house) {
        errors.house = 'Введите номер дома'
    }
    // if (!values.flat) {
    //     errors.flat = 'Введите номер квартиры/офиса'
    // }
    // if (!values.floor) {
    //     errors.floor = 'Укажите этаж'
    // }
    return errors
};

export default validate