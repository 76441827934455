import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Modal from "react-modal";

import carPic from '../../../../images/MyOrdersImg/carPic.svg'
import clockPic from '../../../../images/MyOrdersImg/clockPic.svg'
import housePic from '../../../../images/MyOrdersImg/housePic.svg'
import pinPic from '../../../../images/MyOrdersImg/pinPic.svg'
import BallImg from '../../../../images/MyOrdersImg/points_ball.svg'
import feeInfoImg from "../../../../images/check/fee_info.png";
import OrderFooter from "../OrderFooter";
import { nanoid } from "nanoid";
import moment from "moment";
import 'moment/locale/ru';

Modal.setAppElement("#root")

const OrderContent = ({orderId, ownerId, shortInfo, moreInfo, showMore, companyOrderId, id, status, orderExpDateValue, priceResult}) => {
    const [info, setInfo] = useState(false)

    useEffect(() => {
        console.log(shortInfo, 'shortInfo')
    }, [shortInfo])

    return (
        <OrderContentWrapper>
            <CommonInfo {...{showMore}}>
                <OwnerName>
                    <OwnerLogo src={shortInfo.ownerLogo} alt="owner logo"/>{shortInfo.ownerName}
                </OwnerName>
                <CommonInfoRow>
                    Доставка: {shortInfo.period} • {moment(shortInfo.date).format("D MMMM")}
                </CommonInfoRow>
                <CommonInfoRow>
                    Адрес: {shortInfo.companyName ? shortInfo.companyName.trim() + ', ' : ''}{shortInfo.address}
                </CommonInfoRow>
                <Line />
                {/*<p><img src={pinPic} alt="map pin"/> {shortInfo.address} </p>*/}
                {/*<p><img src={housePic} alt="house"/> {shortInfo.companyName} </p>*/}
                {/*<p><img src={clockPic} alt="clock"/> {shortInfo.period} </p>*/}
                {/*<p><img src={carPic} alt="car"/> {shortInfo.deliveryPrice ? shortInfo.deliveryPrice + "₽" : ("Бесплатно")} </p>*/}
            </CommonInfo>
            {
                showMore && (
                    <MoreInfo>
                        {/*<h4>*/}
                        {/*    <img src={moreInfo.ownerLogo} alt="owner logo"/>{moreInfo.ownerName}*/}
                        {/*</h4>*/}
                        <ProductList>
                            {
                                moreInfo.products.map(product => {
                                    return (
                                        <li key={nanoid()}>
                                            <div>
                                                <span>{product.name}</span>
                                                <span>{product.amount} x {(+product.price) + (product.modifiers ? +product.modifiers.reduce((acc, el) => acc += +el.price, 0) : 0)} ₽</span>
                                            </div>
                                            <div>
                                                {
                                                    product.modifiers && (
                                                        product.modifiers.map(el => {
                                                            return  (
                                                                <span style={{paddingLeft: 10}}>{el.name}</span>
                                                            )
                                                        })
                                                    )
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ProductList>
                        {/*<PaymentInfo>*/}
                        {/*    <p>Наличными: {moreInfo.paymentByCash !== undefined ? (+moreInfo.paymentByCash).toFixed(0) + ' ₽' : '0 ₽'}</p>*/}
                        {/*    <p>Баллами: {moreInfo.paymentByPoints !== undefined ? (+moreInfo.paymentByPoints).toFixed(0) + ' ₽' : '0 ₽'}</p>*/}
                        {/*    <p>Итого: {moreInfo.priceResult !== undefined ? (+moreInfo.priceResult).toFixed(0) + ' ₽' : '0 ₽'}</p>*/}
                        {/*</PaymentInfo>*/}
                    </MoreInfo>
                )
            }
            <Prices>
                {shortInfo.products.map(product => {
                    return (
                        // <li key={nanoid()}>
                        //     <div>
                        //         <span>{product.name}</span>
                        //         <span>{product.amount} x {(+product.price) + (product.modifiers ? +product.modifiers.reduce((acc, el) => acc += +el.price, 0) : 0)} ₽</span>
                        //     </div>
                        //     <div>
                        //         {
                        //             product.modifiers && (
                        //                 product.modifiers.map(el => {
                        //                     return  (
                        //                         <span style={{paddingLeft: 10}}>{el.name}</span>
                        //                     )
                        //                 })
                        //             )
                        //         }
                        //     </div>
                        // </li>
                        <div style={{marginBottom: 16}}>
                            <PriceRow key={nanoid()} style={{marginBottom: 0}}>
                                <PriceText>{product.name}</PriceText>
                                <PriceText>{product.amount}x{(+product.price) + (product.modifiers ? +product.modifiers.reduce((acc, el) => acc += +el.price, 0) : 0)} ₽</PriceText>
                            </PriceRow>
                            {(product.modifiers && product.modifiers.length > 0) ? (
                                <Modifier>
                                    {product.modifiers[0].name}
                                </Modifier>
                            ) : null}
                        </div>
                    )
                })}
                <Line />
            </Prices>
            <Prices>
                <PriceRow>
                    <PriceText>Доставка</PriceText>
                    <PriceText>{+shortInfo.deliveryPrice} ₽</PriceText>
                </PriceRow>
                <PriceRow>
                    <PriceText>
                        Сервисный сбор
                        <img
                            src={feeInfoImg}
                            alt="i"
                            style={{width: 16, height: 16, marginLeft: 8, cursor: 'pointer'}}
                            onClick={() => setInfo(true)}
                        />
                    </PriceText>
                    <PriceText>{+shortInfo.serviceFeePrice} ₽</PriceText>
                </PriceRow>
                <Line />
                <PriceRow style={{marginBottom: 24}}>
                    <PriceText>Итого</PriceText>
                    <PriceText style={{color: '#54933F'}}>{+priceResult} ₽</PriceText>
                </PriceRow>
                <PayTypes>
                    {+shortInfo.paymentByCash > 0 ? (
                        <PriceText style={{marginLeft: 16}}>
                            {+shortInfo.paysystem === 1 ? 'Наличными:'
                                : (+shortInfo.paysystem === 2 || +shortInfo.paysystem === 16) ? 'Картой онлайн:'
                                    : ''} {+shortInfo.paymentByCash} ₽
                        </PriceText>
                    ) : null}
                    {+shortInfo.paymentByPoints > 0 ? (
                        <PriceText style={{marginLeft: 16}}>
                            Баллами: {+shortInfo.paymentByPoints}<img src={BallImg} alt="Б" style={{marginLeft: 8}} />
                        </PriceText>
                    ) : null}
                </PayTypes>
            </Prices>
            <OrderFooter {...{orderId, ownerId, companyOrderId, id, orderExpDateValue}}/>

            <Modal
                isOpen={info}
                closeTimeoutMS={100}
                style={customStylesMobilePoints}
                overlayClassName={"Modifiers__Overlay"}
                onRequestClose={e => {
                    e.stopPropagation()
                    setInfo(false)
                }}
            >
                <ModalWrapper onClick={e => e.stopPropagation()}>
                    <PointsModal>
                        <PointsModalTitle style={{marginBottom: 20}}>
                            Сервисный сбор
                        </PointsModalTitle>
                        <PointsModalText style={{marginBottom: 0}}>
                            Мы развиваемся и растём. Сервисный сбор помогает сделать сервис лучше для вас. Если вы делаете несколько заказов в день, то сервисный сбор добавляется только в ваш первый заказ
                        </PointsModalText>
                    </PointsModal>
                </ModalWrapper>
            </Modal>
        </OrderContentWrapper>
    )
}

export default OrderContent;

const customStylesMobilePoints = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "360px",
        maxHeight: "100vh",
    }
};

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 6px;
    overflow: hidden;
    width: 100%;
    
    @media (max-width: 992px) {
        border-radius: 0;
    }
`

const PointsModal = styled.div`
    width: 100%;
    transition: all .2s linear;
    padding: 22px 26px 26px;
    overflow: hidden;
    position: relative;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }

    @media (max-width: 992px) {
        padding: 25px 37px;
    }
`

const BlockTitle = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
`

const PointsModalTitle = styled(BlockTitle)`
    margin-bottom: 6px;
`

const PointsModalText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 22px;
`

const OrderContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    /*background: yellow;*/
    border-radius: 0px 0px 8px 8px;
`
const CommonInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 0;
    /*background: #fff;*/
    border-radius: ${({showMore}) => showMore ? '8px' : "0 0 8px 8px"};
    
    > p {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      
      :last-child{
        margin-bottom: 0;
      }
      
      > img {
        margin-right: 8px;
      }
    }
    
    > h4 > img {
        width: 24px;
        margin-right: 8px;
    }
    
    @media(max-width: 768px) {
        padding: 24px 16px 0;
    }
`
const MoreInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #fff;
    border-radius: 0 0 8px 8px;
    margin-bottom: 24px;
    
    > h4 {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    > h4 > img {
        width: 24px;
        margin-right: 8px;
    }
`
const ProductList = styled.ul`
    padding: 16px 0;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #F2F3F5;
    
    > li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        
        :nth-child(n+1) {
          margin-bottom: 16px;
        }
        :last-child {
          margin-bottom: 0;
        }
        
        > div {
            display: flex;
            justify-content: space-between;
        }
    }
`
const PaymentInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0 0 0;
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    
    > p {
      margin-bottom: 16px;
      
      :last-child {
         margin-bottom: 0;
      }
    }
`

const OwnerName = styled.p`
    margin-bottom: 16px;
    font-family: "SF Display", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000
`

const OwnerLogo = styled.img`
    width: 24px;
    margin-right: 8px;
`

const CommonInfoRow = styled.p`
    margin-bottom: 16px;
    font-family: "SF Display", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #2C2E32;
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
    background: #F1F1F1;
`

const Prices = styled.div`
    padding: 0 32px;
    
    @media(max-width: 768px) {
        padding: 0 16px;
    }
`

const PriceRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
`

const PriceText = styled.p`
    margin-bottom: 0;
    font-family: "SF Display", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
`

const PayTypes = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const Modifier = styled.p`
    margin-bottom: 0;
    margin-left: 12px;
    font-family: "SF Display", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
`
