import React from "react";
import styled from "styled-components";
import iconPreload from "./../../images/preload_fallback.gif";

export const Fallback = () => (
	<Container>
		<Preload src={iconPreload} />
	</Container>
);

export default Fallback;

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	transition: 350ms linear;
`;

const Preload = styled.img`
	width: 124px;
	height: 124px;
`;