const validate = values => {
    const errors = {};

    if (!values.street) {
        errors.street = 'Введите название улицы'
    }
    if (!values.house) {
        errors.house = 'Введите номер дома'
    }
    if (!values.flat) {
        errors.flat = 'Введите номер квартиры/офиса'
    }
    if (!values.name) {
        errors.name = 'Введите ваше имя'
    }
    if (!values.company) {
        errors.company = 'Введите наименование кампании'
    }
    if (values.pass_enter && values.pass_enter.length <= 6) {
        errors.pass_enter = 'Пароль должен содержать минимум 6 символов'
    }
    return errors
};

export default validate