import React, {Fragment, PureComponent} from "react";
import Modal from "react-modal";
import styled from "styled-components";
import caleydoscop from "../../../images/caleydoscop2.svg";
import {Field, reduxForm} from 'redux-form'
import validate from './validate';
import * as _ from "underscore";
import {connect} from "react-redux";
import {saveClientProfile} from "../../../actions/ProfileActions";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import {CSSTransition} from "react-transition-group";
import {IS_51_OBED} from "../../../utils/constants";

Modal.setAppElement("#root");

class EditModalConfig extends PureComponent {
    state = {
        stateAuthModal: this.props.hash ? 'reg' : 'edit',
        openModal: this.props.hash ? true : false,
        initialDate: {},
        validPassword: true,
        errorPass: '',
    };

    authModalToggle = () => {
        this.setState({
            openModal: !this.state.openModal,
        })
    };

    activationAccount = () => {
        let props = this.props;
        let pass = props.valueReg.pass_enter;
        let pass_repeat = props.valueReg.pass_repeat_enter;
        let dataParams = {};

        _.map(props.params, (item)=>{
            dataParams[item.name] = item.value
        });

        dataParams.fio = props.valueReg.name_user;
        dataParams.password = pass;

        if(pass === pass_repeat && pass.length >= 6){
            this.setState({
                validPassword: true
            });

            this.props.activateEmailAccount(dataParams);
            this.authModalToggle();
            this.props.history.push('/');
        } else if (pass !== pass_repeat) {
            this.setState({
                validPassword: false,
                errorPass: 'Пароли не совпадают'
            });

            setTimeout(()=>{
                this.setState({
                    validPassword: true,
                    errorPass: ''
                });
            }, 2000)
        }
    };

    renderField = ({ input, label, type, meta: { touched, error }}) => (
        <WrapperString>
            <String>
                <LabelAuth error={touched && error}>{label} <ReqInput>*</ReqInput></LabelAuth>
                <AuthInput {...input} type={type} placeholder={label} error={touched && error}/>
            </String>
            {touched && error && <ErrorSpan>{error}</ErrorSpan>}
        </WrapperString>
    );

    render() {
        const {
            handleReduxFormReg,
            valueReg
        } = this.props;

        const {
            openModal,
            stateAuthModal,
            toggleTransform,
        } = this.state;

        return (
            <Fragment>
                <Modal
                    closeTimeoutMS={100}
                    isOpen={openModal}
                    style={customStyles}
                    overlayClassName={"Modifiers__Overlay"}
                >
                    <ModalWrapper>
                        <LeftWrapperBlock action={stateAuthModal}>
                            <CSSTransition timeout={500} in={stateAuthModal === 'reg'} classNames="reg" unmountOnExit>
                                {()=><LeftBlockRegistration action={stateAuthModal}>
                                    <RegTitle>Добро пожаловать!</RegTitle>
                                    <RegForm>
                                        <RegEmail>
                                            <RegRow onSubmit={handleReduxFormReg}>
                                                <Field
                                                    name="name_user"
                                                    type="text"
                                                    component={this.renderField}
                                                    label="Ваше имя"
                                                />
                                                <Field
                                                    name="pass_enter"
                                                    type="password"
                                                    component={this.renderField}
                                                    label="Введите пароль"
                                                />
                                                <Field
                                                    name="pass_repeat_enter"
                                                    type="password"
                                                    component={this.renderField}
                                                    label="Повторите пароль"
                                                />
                                                <RegButton
                                                    onClick={()=>{_.size(valueReg) === 3 && this.activationAccount()}}
                                                    className={_.size(valueReg) === 3 ? 'active-reg-account' : 'no-active-reg-account'}
                                                    is51obed={_.size(valueReg) === 3 && IS_51_OBED}
                                                >
                                                    Активация
                                                </RegButton>
                                            </RegRow>
                                        </RegEmail>
                                    </RegForm>
                                    <MessError error={this.state.validPassword}>
                                        Произошла ошибка: {this.state.errorPass}
                                    </MessError>
                                </LeftBlockRegistration>}
                            </CSSTransition>
                        </LeftWrapperBlock>
                    </ModalWrapper>
                </Modal>
            </Fragment>
        );
    }
}

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "750px",
    }
};

const AuthInputEmail = styled(Field)`
    width: 60%;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 8px 16px;
	text-align: left;
	transition: 0.15s ease all;
	outline: none;
`;

const RegInput = styled(AuthInputEmail)``;

const mapStateToProps = store => ({
    valueInfoProfile: store.profile.valueInfoProfile,
    profile: store.profile.profile,
    company: store.profile.company,
});

const mapDispatchToProps = dispatch => ({
    saveClientProfile: (profile) => dispatch(saveClientProfile(profile)),
    deliveryCycleInfoMessage: (actionUser, product) => dispatch(deliveryCycleInfoMessage(actionUser, product))
});

export default reduxForm({
    form: 'simple_edit',
    enableReinitialize :  true,
    validate
})(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditModalConfig))

const RegButton = styled.div`
	margin: 25px auto 0;
    display: block;
	background-color: ${({is51obed}) => is51obed ? '#32CD32 !important' : '#E0D5D2'}
	border: none;
	border-radius: 6px;
	padding: 8px 26px;
    text-decoration: none;

	&:focus {
		outline: none;
	}
	
	&:hover{
	    text-decoration: none;
	    ${({is51obed}) => is51obed ? 'background-color: #197019 !important' : null}
	}
`;

const RegEmail = styled.div`
    width: 100%;
`;

const StringAuthEmail = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
`;

const Icon = styled.img`
  width: 100%;
  cursor: pointer;
`;

const TitleIcon = styled.div`
  width: 24px;
  opacity: 0.3;
`;

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 12px;
	display: flex;
    overflow: hidden;
`;

const AuthForm = styled.div`
    width: 100%;
    height: 78%;
    position: relative;
`;

const RegForm = styled(AuthForm)`
    
`;

const RegInfoText = styled.p`
    margin-bottom: 2rem;
`;

const EditInfo = styled.div`
    width: 100%;
    transition: opacity .2s linear, top .4s linear;
`;

const AuthTitle = styled.p`
	text-align: left;
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 2rem;
`;

const RegTitle = styled(AuthTitle)``;

const AuthInput = styled.input`
    width: 60%;
	border: 1px solid ${({error}) => error ? '#f00' : '#ccc'};
	border-radius: 5px;
	padding: 8px 16px;
	text-align: left;
	transition: 0.15s ease all;
	outline: none;
	background: ${({error}) => error ? 'rgba(255,0,0,0.3)' : '#F5F5F5'};
	
    &::-webkit-input-placeholder {
		color: ${({error}) => error ? '#fff' : '#999'};
	}
	&:-moz-placeholder {
		color: ${({error}) => error ? '#fff' : '#999'};
	}
	&::-moz-placeholder {
		color: ${({error}) => error ? '#fff' : '#999'};
	}
	&:-ms-input-placeholder {
		color: ${({error}) => error ? '#fff' : '#999'};
	}
`;

const String = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
`;

const WrapperString = styled.div`
    position: relative;
`;

const LabelAuth = styled.div`
    width: 30%;
    display: flex;
    color: ${({error}) => error ? '#f00' : 'inherit'};
    margin-right: 15px;
`;

const AuthRowEmail = styled.form`
	margin-bottom: 15px;
    display: flex;
    flex-direction: column;

	&:last-child {
		margin-bottom: 0;
	}
`;

const RegRow = styled(AuthRowEmail)`
	margin-bottom: 15px;
    display: flex;
    flex-direction: column;

	&:last-child {
		margin-bottom: 0;
	}
`;

const AuthButton = styled.div`
	display: block;
	margin: 35px auto 0;
	background: ${({active}) => active ? '#E0D5D2' : 'rgba(241,229,226,0.8)'};
	border: none;
	border-radius: 6px;
	color: ${({active}) => active ? '#fff' : 'rgba(0,0,0,0.3)'};
	padding: 8px 26px;
	cursor: ${({active}) => active ? 'pointer' : 'not-allowed'};

	&:focus {
		outline: none;
	}
`;

const LeftBlock = styled.div`
    width: 100%;
    
    &.edit-enter {
        opacity: 0;
        transform: translateX(170px);
        transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
    }
    
    &.edit-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    
    &.edit-exit {
        opacity: 1;
    }
    
    &.edit-exit-active {
        opacity: 0;
        transform: translateX(170px);
        transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
    }
`;

const LeftWrapperBlock = styled.div`
    width: 100%;
    padding: 25px;
    transition: .3s linear;
    position: relative;
`;

const ImageFone = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 2s cubic-bezier(0.58, 0.27, 0.31, 0.85);
    padding: 40px;
`;

const ButtonToggle = styled.div`
    box-sizing: border-box;
    border-radius: 66px;
    color: #fff;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    transition: 1s cubic-bezier(0.58, 0.27, 0.31, 0.85);
`;

const RightBlock = styled.div`
    width: 50%;
    background: linear-gradient(48.18deg, #9DB3EE 1.4%, #C7D7F7 98.82%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s linear;
    position: relative;
    overflow: hidden;
    transform: ${({action}) => action === 'reg' ? 'translate(-100%)' : 'translate()'};
    
    &:hover ${ImageFone}{
      transform: scale(1.2) rotate(65deg);
    }
    
    &:hover ${ButtonToggle}{
      transform: scale(1.1);
    }
`;

const TextSpan = styled.span`
    font-family: Lato, sans-serif;
    font-size: 28px;
    color: #FFFFFF;
`;

const TextSubSpan = styled.span`
    font-family: Lato, sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 15px;
`;

const ReqInput = styled.div`
    color: red;
    margin-left: 3px;
`;

const ErrorSpan = styled.span`
    position: absolute;
    bottom: 3px;
    left: 30%;
    margin-left: 15px;
    color: red;
    font-size: 12px;
`;

const LeftBlockRegistration = styled.div`
    width: 100%;
    height: 100%;
    z-index: ${({action}) => action === 'reg' ? '1' : '0'};
    transition: all .2s linear;
    padding: 25px;
    
    &.reg-enter {
        opacity: 0;
        transform: translateX(170px);
        transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
    }
    
    &.reg-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms, transform 500ms;
    }
    
    &.reg-exit {
        opacity: 1;
    }
    
    &.reg-exit-active {
        opacity: 0;
        transform: translateX(170px);
        transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
    }
`;

const MessError = styled.div`
    background: rgba(160,0,0,0.81);
    width: 100%;
    height: 40px;
    position: absolute;
    transition: .3s linear;
    bottom: ${({error}) => !error ? '0' : '-40px'};
    left: 0;
    overflow: hidden;
    padding-left: 25px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    opacity: ${({error}) => !error ? '1' : '0'};
`;
