import {
	SHOW_MY_INVITES_REQUEST,
	SHOW_MY_INVITES_SUCCESS,
	SHOW_MY_INVITES_ERROR,
	GET_MY_CARDS_REQUEST,
	GET_MY_CARDS_SUCCESS,
	GET_MY_CARDS_ERROR,
	LOADER_PAY
} from "../actions/PersonalAction";

const initialState = {
	companies: [],
	errorGetCompanies: false,
	myInvites: [],
	errorMyInvites: false,
	myCard: [],
	getMyCardsError: false,
	cardsLoading: false,
	payLoading: false
};

export function personalReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_MY_INVITES_REQUEST:
			return {
				...state,
				myInvites: [],
				errorMyInvites: false
			};

		case SHOW_MY_INVITES_SUCCESS:
			return {
				...state,
				myInvites: action.payload,
				errorMyInvites: false
			};

		case SHOW_MY_INVITES_ERROR:
			return {
				...state,
				myInvites: [],
				errorMyInvites: true
			};

		case GET_MY_CARDS_REQUEST:
			return {
				...state,
				getMyCardsError: false,
				cardsLoading: true
			};

		case GET_MY_CARDS_SUCCESS:
			return {
				...state,
				myCard: action.payload,
				getMyCardsError: false,
				cardsLoading: false
			};

		case GET_MY_CARDS_ERROR:
			return {
				...state,
				myCard: [],
				getMyCardsError: true,
				cardsLoading: true
			};

		case LOADER_PAY:
			return {
				...state,
				payLoading: action.payload
			};

		default:
			return state;
	}
}
