import React from "react";
import styled from "styled-components";

const Modal = ({ visible, onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget && onClose) {
      onClose();
    }
  };

  return (
    <ModalOverlay visible={visible} onClick={handleOverlayClick}>
      <ModalContainer>{children}</ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @media (max-width: 748px) {
    align-items: flex-end;
  }
`;

const ModalContainer = styled.div`
  background-color: #ffffff;
  padding: 60px;
  border-radius: 60px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 530px;
  max-height: 90%;
  overflow-y: auto;

  @media (max-width: 748px) {
    padding: 24px;
    border-radius: 32px 32px 0 0;
  }
`;
