import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {BrowserRouter} from "react-router-dom";
import App from "./containers/App";
import "bootstrap/dist/css/bootstrap-reboot.min.css";
import "./index.css";
import store from "./store";
import * as serviceWorker from "./utils/serviceWorker";
import "./utils/date";
import "./utils/window";
import 'react-app-polyfill/ie11';
import { YMInitializer } from 'react-yandex-metrika';
import ReactPixel from 'react-facebook-pixel';

localStorage.removeItem('payProcessing');

if (document.location.protocol === 'https:') {
	caches.keys().then(function(names) {
		for (let name of names) {
			console.log(name, 'cache name')
			caches.delete(name).then(res => console.log('cache is cleared?', res));
		}
	})
}

let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

if (!is51obed && !isStraus) {
	const options = {
		autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
		debug: true, // enable logs
	};
	ReactPixel.init('427932828483729', options);
}

render(
	<Provider store={store}>
		{(!is51obed && !isStraus) ? (
			<YMInitializer accounts={[50456719]} options={{clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}} version="2"/>
		) : null}
		<BrowserRouter basename={"/"}>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
