import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import styled from "styled-components";
import {getRestaurants} from "../../actions/MainActions";
import {authModalToggle, cityModalToggle, profileModalToggle} from "../../actions/ModalActions";
import {
	createTokenByPassword,
	createTokenBySession,
	deleteToken,
	getClientProfile,
	handleReduxFormAuth,
	saveClientProfile,
	sendCodeToPhone
} from "../../actions/ProfileActions";
import Nav from "../../components/Header/Nav";
import logo from "../../images/logo_new.svg";
import logo51 from "../../images/logo_new.png";
import logoStraus from "../../images/logo_new.jpg";
import {_} from "underscore";
import {getOrders} from "../../actions/MenuActions";
import {NAME_COMPANY, TXT_COLOR} from "../../utils/constants";
import AuthModal from "../../components/Modal/AuthModal";
import profileLogo from "../../images/marker/mark_user.svg";

class HeaderMini extends Component {
	static propTypes = {
		cities: PropTypes.array.isRequired,
		todayOrdersCount: PropTypes.number.isRequired,
		nextOrdersCount: PropTypes.number.isRequired,
		getRestaurants: PropTypes.func.isRequired,
	};

	getCookie (name) {
		let value = "; " + document.cookie;
		let parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

	componentDidMount() {
		this.props.getClientProfile();
	}

	render() {
		const {
			isAuth,
			profile,
			sendCodeToPhone,
			createTokenBySession,
			codeSent,
			phoneError,
			authModalIsOpen,
			authModalToggle,
			valueAuthEmail,
            errorMessage,
		} = this.props;

		let cookieCheck = this.getCookie("server");

		let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
		let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

        return (
			<Container style={this.props.fixed != null ? this.props.fixed ? {} : {opacity: '0', visibility: 'hidden'} : {}} id="headerMini">
				<Wrapper>
					<Left>
						{
							cookieCheck === "main" || !cookieCheck
								? false
								: <AnchorDevMainServer>
									<Anchor>Dev server</Anchor>
								</AnchorDevMainServer>
						}
						<LogoLink to="/">
							<LogoImg
								src={
									is51obed ? logo51 :
										isStraus ? logoStraus :
											logo
								}
								rounded={isStraus}
								alt={NAME_COMPANY}
							/>
						</LogoLink>
					</Left>
					<Center>
						<Nav fixedMenu={this.props.fixed}/>
					</Center>
					<Right>
						{isAuth ? (
							<ProfileToggleInfo>
								<ProfileIcon src={profileLogo}/>
								<ProfileToggleText to="/personal" style={{display: ''}}>
									{profile && `${
										profile.name ? profile.name : profile.phone || profile.phone
									}`}
								</ProfileToggleText>
							</ProfileToggleInfo>
						) : (
                            <AuthModal
								onChange={this.props.handleReduxFormAuth}
                                profile={profile}
                                codeSent={codeSent}
                                phoneError={phoneError}
                                sendCodeToPhone={sendCodeToPhone}
                                createTokenBySession={createTokenBySession}
                                authModalIsOpen={authModalIsOpen}
                                authModalToggle={authModalToggle}
                                errorMessage={errorMessage}
								valueAuthEmail={valueAuthEmail}
								buttonFront={false}
								createTokenByPassword={this.props.createTokenByPassword}
							/>
						)}
					</Right>
				</Wrapper>
			</Container>
		);
	}
}

const mapStateToProps = store => ({
	cities: store.main.cities,
    orders: store.menu.orders,
    todayOrdersCount: store.header.todayOrdersCount,
	nextOrdersCount: store.header.nextOrdersCount,
	isAuth: store.profile.isAuth,
	profile: store.profile.profile,
	valueAuthEmail: store.profile.valueAuthEmail,
	errorMessage: store.profile.errorMessage,
    codeSent: store.profile.codeSent,
	phoneError: store.profile.phoneError,
	cityModalIsOpen: store.modal.cityModalIsOpen,
	authModalIsOpen: store.modal.authModalIsOpen,
	profileModalIsOpen: store.modal.profileModalIsOpen,
	sumCount: store.menu.sum
});

const mapDispatchToProps = dispatch => ({
	getRestaurants: () => dispatch(getRestaurants()),
	getClientProfile: () => dispatch(getClientProfile()),
	sendCodeToPhone: phone => dispatch(sendCodeToPhone(phone)),
	createTokenBySession: code => dispatch(createTokenBySession(code)),
	deleteToken: () => dispatch(deleteToken()),
	saveClientProfile: profile => dispatch(saveClientProfile(profile)),
	cityModalToggle: () => dispatch(cityModalToggle()),
	authModalToggle: () => dispatch(authModalToggle()),
	profileModalToggle: () => dispatch(profileModalToggle()),
	getOrders: (items) => dispatch(getOrders(items)),
	handleReduxFormAuth: (values) => dispatch(handleReduxFormAuth(values)),
	createTokenByPassword: () => dispatch(createTokenByPassword()),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(HeaderMini)
);

const ProfileToggleInfo = styled.span`
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
	color: ${TXT_COLOR};
	
	@media(min-width: 992px){
		flex-direction: row;
		align-items: center;
	}	
	
	&:hover{
		color: #223;
		text-decoration: none;
	}
`;

const ProfileIcon = styled.img`
	width: 100%;
	
	@media(min-width: 992px){
		width: 22px;
		margin-right: -15px;
	}
`;

const ProfileToggleText = styled(NavLink)`
	box-sizing: border-box;
	color: ${TXT_COLOR};
	font-size: 16px;
	line-height: 19px;
	padding: 5px 20px;
	cursor: pointer;
	display: none;
	transition: .2s linear;

	&:hover {
		text-decoration: none;
		text-shadow: 0 0 3px #c0c0c0;
	}
	
	@media(min-width: 992px){
		display: block !important;
	}	
	
	@media(min-width: 992px){
		order: 1;
	}
`;

const Container = styled.header`
	margin-top: -46px;
	border: 1px solid #e9e9e9;
	// box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	//transition: .2s cubic-bezier(0.39, 0.58, 0.57, 1);
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2000;
	opacity: 1;
	visibility: visible;
	
	@media(max-width: 992px){
		display: none;
	}
`;

const Wrapper = styled.div`
	max-width: 1280px;
	padding: 4px 15px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	@media (max-width: 992px) {
		padding: 4px 15px;
	}
`;

const Left = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	@media (max-width: 992px) {
		flex: 0;
	}
`;

const LogoLink = styled(Link)`
	margin-right: 15px;
`;

const LogoImg = styled.img`
	height: 36px;
	border-radius: ${({rounded}) => rounded ? '18px' : '0px'};
`;

const Center = styled.div`
	text-align: center;
	color: #2d2d2d;
	flex: 2;

	@media (max-width: 768px) {
		flex: 0;
		order: 3;
		display: inline-flex;
		align-items: center;
	}
`;

const Right = styled.div`
	display: flex;
    align-items: center;
	flex: 1;
	justify-content: flex-end;

	@media (max-width: 992px) {
		flex: 0;
		margin-left: auto;
		display: inline-flex;
		align-items: center;
		display: flex;
		flex-direction: inherit;
	}
`;

const AnchorDevMainServer = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: auto;
	height: 30px;
	background: red;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
`;

const Anchor = styled.span`
	box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.05);
	color: #fff;
`;
