import React from "react";
import styled from "styled-components";

const Input = ({ placeholder, value, onChange, onEnter }) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnter && onEnter(value);
        }
    };

    return (
        <StyledInput
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={handleKeyDown}
        />
    );
};

export default Input;

const StyledInput = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 16px;
    background: #F9F9F9;
    border: solid 1px #eaeaea;
    border-radius: 16px;
    font-family: 'Gilroy', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #2C2E32;
    outline: none;
`;
