import React from 'react';
import styled from 'styled-components';

const Title = ({ style, className, level, align, color, link, children }) => {
  return (
    <TitleWrapper
        style={style}
        className={className}
        level={level}
        align={align}
        color={color} 
    >
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <>{children}</>
      )}
    </TitleWrapper>
  );
};

export default Title;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: ${(props) =>     props.level === 1 ? '32px' : 
                                props.level === 2 ? '24px' : 
                                props.level === 3 ? '20px' : 
                                props.level === 4 ? '18px' : 
                                props.level === 5 ? '16px' : 
                                '24px'};
    font-weight: ${(props) =>   props.level === 1 ? '700' : 
                                props.level === 2 ? '500' : 
                                props.level === 3 ? '500' : 
                                props.level === 4 ? '500' : 
                                props.level === 5 ? '500' : 
                                '500'};
    text-align: ${(props) => props.align && props.align};                      
    color: ${(props) => props.color ? props.color : '#313131'};

    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: var(--color-accent);
            text-decoration: none;
        }
    }
`;
