import React from "react";
import styled from "styled-components";

const Card = ({ className, title, text, action, image, children }) => {
    return (
        <CardWrapper className={className}>
            <Flex>
                {image && <CardImage src={image}/>}
                <div>
                    {title && <CardTitle>{title}</CardTitle>}
                    {text && <CardText>{text}</CardText>}
                </div>
                {action && <Action>{action}</Action>}
            </Flex>
            {children}
        </CardWrapper>
    );
};

export default Card;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 530px;
    height: auto;
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 44px;
    box-shadow: 0px 20px 24px 0px #00000029;
    pointer-events: all;

    @media (max-width: 748px) {
        padding: 24px;
        width: 100%;
        min-height: 170px;
        min-width: auto;
        border-radius: 44px 44px 0 0;
        justify-content: center;
    }
`
const Flex = styled.div`
    display: flex;
    gap: 16px;
    @media (max-width: 748px) {
        gap: 8px;
        flex-direction: column;
    }
`
const CardImage = styled.img`
    width: 50px;
    height: 50px;
`
const CardTitle = styled.div`
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #313131;
`
const CardText = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: #2A2A2A;
    @media (max-width: 748px) {
        font-size: 16px;
    }
`
const Action = styled.div`
    
`
