import React, {useContext} from "react";
import styled from "styled-components";
import {MAIN_COLOR} from "../../../../utils/constants";
import {useDispatch} from "react-redux";
import {toggleCancelModal} from "../../../../actions/MyOrdersActions";

const OrderFooter = ( {orderId, ownerId, companyOrderId, id, orderExpDateValue} ) => {

    const dispatch = useDispatch()

    return (
        <FooterWrapper>
            {
                !(new Date().getTime() >= orderExpDateValue) && (
                    <Button onClick={() => {
                        dispatch(toggleCancelModal({orderId, ownerId, companyOrderId, id}))
                    }}>
                        Отменить заказ
                    </Button>
                )
            }
        </FooterWrapper>
    )
}

export default OrderFooter;

const FooterWrapper = styled.footer`
  /*padding-top: 10px;*/
  padding-top: 24px;
  padding-right: 32px;
  display: flex;
  
  @media(max-width: 768px) {
    /*padding: 10px;*/
    padding-right: 16px;
  } 
`

const Button = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 6px;
  background: ${MAIN_COLOR};
  color: white;
  margin-left: auto;
  margin-bottom: 24px;
  font-size: 14px;
`
