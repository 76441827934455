import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Stories = ({ images, duration = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);

  const startProgress = () => {
    clearInterval(intervalRef.current);
    setProgress(0);
    intervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(intervalRef.current);
          nextStory();
        }
        return prev + 100 / (duration / 100);
      });
    }, 100);
  };

  const nextStory = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const selectStory = (index) => {
    clearInterval(intervalRef.current);
    setCurrentIndex(index);
    setProgress(0);
  };

  useEffect(() => {
    startProgress();
    return () => clearInterval(intervalRef.current);
  }, [currentIndex]);

  return (
    <StoriesWrapper>
        <ProgressBarContainer>
            {images.map((_, index) => (
                <ProgressBar key={index} onClick={() => selectStory(index)}>
                    <Progress progress={index === currentIndex ? progress : index < currentIndex ? 100 : 0}/>
                </ProgressBar>
            ))}
        </ProgressBarContainer>
        <Image src={images[currentIndex]} alt={`Story ${currentIndex + 1}`}/>
    </StoriesWrapper>
  );
};

export default Stories;

const StoriesWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 32px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ProgressBarContainer = styled.div`
  position: absolute;
  top: -16px;
  width: 100%;
  display: flex;
  gap: 5px;
`
const ProgressBar = styled.div`
  flex: 1;
  height: 3px;
  background: #b2b2b2;
  border-radius: 2px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`
const Progress = styled.div`
  height: 100%;
  background: #2a2a2a;
  width: ${(props) => props.progress}%;
  transition: width 0.1s linear;
`
