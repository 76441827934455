import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../../../utils/request';

export const getCities = createAsyncThunk('common/getCities', async (_, {rejectWithValue }) => {
    try {
        const response = await request("AppDinnerController", "GetZoneDelivery", {});

        return response.data.cities;
    } catch (error) {
        console.error(error);
        return rejectWithValue('Ошибка подключения');
    }
});

const initialState = {
    device: 'desctop',
    orientation: 'portrait',

    getCitiesStatus: null,
    cities: [],
    userCurrentCityId: null,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setDevice: (state, action) => {
            state.device = action.payload;
        },
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        setUserCurrentCityId: (state, action) => {
            state.userCurrentCityId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCities.pending, (state) => {
                state.getCitiesStatus = 'pending';
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.getCitiesStatus = 'fulfilled';
                state.cities = action.payload;
            })
            .addCase(getCities.rejected, (state) => {
                state.getCitiesStatus = 'rejected';
            })
    },
});

export const {
    setDevice,
    setOrientation,
    setUserCurrentCityId,
    setSelectPromo,
} = commonSlice.actions;

export default commonSlice.reducer;
