import {
  RESET_ADD_COMPANY,
  SET_ADDRESS_ADDITIONAL_COMPONENTS,
  SET_ADDRESS_COMPONENTS,
  SET_ADDRESS_POINT,
  SET_ADDRESS_STRING
} from "../actions/AddCompanyActions"

const init = {
  addressObj: {},
  addressStr: "",
  addressPoint: null,
  addressAdditional: {
    addressType: '',
    companyName: '',
    office: '',
    apartment: '',
    floor: '',
    flat: '',
    comment: '',
  }
}

export const addCompany = (state = init, action) => {
  switch (action.type){
    case SET_ADDRESS_COMPONENTS:
      return {
        ...state,
        addressObj: action.payload
      }
    case SET_ADDRESS_POINT:
      return {
        ...state,
        addressPoint: action.payload
      }
    case SET_ADDRESS_STRING:
      return {
        ...state,
        addressStr: action.payload
      }
    case SET_ADDRESS_ADDITIONAL_COMPONENTS:
      return {
        ...state,
        addressAdditional: action.payload
      }
    case RESET_ADD_COMPANY:
      return init
    default:
      return state
  }
}
