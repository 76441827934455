import React, {useEffect, useState} from "react"
import styled from "styled-components"

const FormField = ({
                     placeholder = "temp placeholder",
                     error = 'error example',
                     type = 'input',
                     maxAreaLen = 100,
                     showError = false,
                     val = '',
                     setVal,
}) => {
  return (
    <InputWrapper error={showError}>
      <div style={{flexGrow: 1, width: '100%'}}>
        {
          type === 'input' && (
            <Input type='text'
                   error={showError}
                   placeholder={placeholder}
                   value={val}
                   onChange={e => setVal(e.target.value)}/>
          )
        }
        {
          type === 'textarea' && (
            <TextArea type='text'
                      placeholder={placeholder}
                      maxLength={maxAreaLen}
                      value={val}
                      onChange={e => setVal(e.target.value)}
                      maxAreaLen={maxAreaLen}/>
          )
        }
        {showError && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </InputWrapper>
  )
}

export default FormField

const Input = styled.input`
  width: 100%;
  flex-grow: 1;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid ${({error}) => error ? 'rgba(255,102,102,0.7)' : 'rgba(0, 0, 0, 0.7)'};
  
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  position: relative;
  resize: none;
`
const ErrorMessage = styled.span`
  color: red;
  padding-left: 10px;
`

const InputWrapper = styled.div`
  padding: ${({error}) => !error ? "10px 0" : "10px 0 0 0"} ;
  display: flex;
  
  @media(max-width: 996px) {
    flex-direction: column;
  }
`
export const Label = styled.label`
  width: 50%;
  display: flex;
  align-items: center;
  ${({type}) => type === 'textarea' && {alignSelf: 'flex-start'}};

  @media(max-width: 996px) {
    width: 100%;
  }
`
