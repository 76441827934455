import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import ym from "react-yandex-metrika";
import styled, { keyframes } from 'styled-components';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import { regClient, resetAuthState, sendCode, sendPhone } from '../../../features/utils/authReducer';
import Spinner from '../../../components/Spinner';
import PinInput from '../../../components/PinInput';
import { setAditionalAddressGeocodeObject } from '../../../features/utils/mapReducer';
import Input from '../../../components/Input';

const AuthForm = ({ showAuthPopup }) => {
    const dispatch = useDispatch();
    const { regClientStatus, dataResult, dataMessage } = useSelector((state) => state.interactiveMapPromo.auth);
    const [phone, setPhone] = useState('');
    const [showPinInput, setShowPinInput] = useState(false);
    const [isCodeValid, setIsCodeValid] = useState(null);
    const [timer, setTimer] = useState(60);
    const [isInfoAdded, setIsInfoAdded] = useState(false);
    const [info, setInfo] = useState({
        companyType: 1, // 1 - это компания, 2 - дом.
        name: '',
        entrance: '',
        flat: '',
        floor: '',
        comment: '',
    });

    useEffect(() => {
        setPhone('')
        setShowPinInput(false)
        setIsCodeValid(null)
        setIsInfoAdded(false)
        setInfo({
            companyType: 1,
            name: '',
            entrance: '',
            flat: '',
            floor: '',
            comment: '',
        })
    }, [showAuthPopup])

    const handleTypeChange = (type) => {
        setInfo((prev) => ({
            ...prev,
            companyType: type,
            name: type === 2 ? 'Дом' : undefined,
        }));
    };

    const handleInputChange = (field, value) => {
        setInfo((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleInfoSubmit = () => {
        dispatch(setAditionalAddressGeocodeObject(info));
        setIsInfoAdded(true);
    }

    const handlePhoneSubmit = () => {
        const formattedPhone = `+${phone.replace(/[^0-9]/g, '')}`;

        dispatch(sendPhone(formattedPhone))
            .then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    setShowPinInput(true);
                    startTimer();
                } else {
                    alert('Повторную отправку SMS с кодом можно запросить через несколько минут.');
                }
            })
            .catch((error) => {
                console.error('Ошибка выполнения dispatch:', error);
            });
    };

    const backToPhone = () => {
        setShowPinInput(false);
        dispatch(resetAuthState());
    };

    const handlePinComplete = (value) => {
        dispatch(sendCode(value))
            .then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    setIsCodeValid(true);
                    dispatch(regClient())
                        .then(() => {
                            ym('reachGoal','phone_and_pin_corrected');
                        })
                        .catch((error) => {
                            console.error('Ошибка выполнения dispatch:', error);
                        });
                } else {
                    setIsCodeValid(false);
                }
            })
            .catch((error) => {
                setIsCodeValid(false);
                console.error('Ошибка выполнения dispatch:', error);
            });
    };

    const startTimer = () => {
        let countdown = 60;
        const interval = setInterval(() => {
            countdown -= 1;
            setTimer(countdown);
            if (countdown === 0) {
                clearInterval(interval);
            }
        }, 1000);
    };

    useEffect(() => {
        console.log(dataResult)
        if (dataResult === 1) {
            ym('reachGoal','phone_success');
        } else {
            ym('reachGoal','phone_rejected');
        }
    }, [dataResult])

    return (
        <>
            {!isInfoAdded ?
                <>
                    <Text>Перед доставкой курьер позвонит, но вы можете помочь найти вас быстрее</Text>

                    <InlineGroup>
                        <ToggleLabel active={info.companyType === 1} onClick={() => handleTypeChange(1)}>
                            <RadioButton active={info.companyType === 1} />
                            Работа
                        </ToggleLabel>
                        <ToggleLabel active={info.companyType === 2} onClick={() => handleTypeChange(2)}>
                            <RadioButton active={info.companyType === 2} />
                            Дом
                        </ToggleLabel>
                    </InlineGroup>

                    {info.companyType === 1 && (
                        <Input
                        value={info.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        placeholder="Ваша компания"
                        />
                    )}

                    <InlineGroup>
                        <Input
                            type="numeric"
                            value={info.entrance}
                            onChange={(e) => handleInputChange('entrance', e.target.value)}
                            placeholder="Подъезд"
                        />
                        <Input
                            type="numeric"
                            value={info.floor}
                            onChange={(e) => handleInputChange('floor', e.target.value)}
                            placeholder="Этаж"
                        />
                        <Input
                            type="numeric"
                            value={info.flat}
                            onChange={(e) => handleInputChange('flat', e.target.value)}
                            placeholder={info.companyType === 1 ? "Офис" : 'Кв.'}
                        />
                    </InlineGroup>

                    <Input
                        value={info.comment}
                        onChange={(e) => handleInputChange('comment', e.target.value)}
                        placeholder="Комментарий к адресу"
                    />

                    <StyledButton type='primary' onClick={handleInfoSubmit}>Далее</StyledButton>
                </>
            : !showPinInput ?
                <>
                    <StyledTitle level={1}>Отлично! Ещё немного...</StyledTitle>
                    <Text>Для доставки обеда, пожалуйста, укажите ваш номер телефона</Text>
                    <StyledPhoneInput
                        mask="+7 (999) 999-99-99"
                        inputmode="numeric"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="+7 (000) 000-00-00"
                    />

                    <StyledButton type='primary' onClick={handlePhoneSubmit} disabled={phone.replace(/[^0-9]/g, '').length < 11}>
                        Получить код из СМС
                    </StyledButton>

                    <TermsLink to={'https://api.menuforme.online/files/146923/static/userpolicy.html?time=164396005052412'}>При авторизации, вы соглашаетесь со сбором, <span>обработкой персональных данных и Пользовательским соглашением</span></TermsLink>
                </>
            :
                <>
                    <StyledTitle>Введите код из СМС</StyledTitle>
                    <Text>Мы отправили сообщение для входа на номер <a style={{textDecoration: 'underline'}} onClick={backToPhone}>{phone}</a></Text>
                    <PinContainer>
                        <PinInput
                            length={4}
                            focus
                            onComplete={handlePinComplete}
                            type="numeric"
                            inputMode="number"
                            inputStyle={{
                                width: '60px',
                                height: '60px',
                                margin: '5px',
                                fontSize: '24px',
                                borderRadius: '16px',
                                borderColor: isCodeValid === false ? '#EA394B' : isCodeValid === true ? '#49BC9A' : '#EAEAEA',
                                backgroundColor: '#F9F9F9',
                            }}
                        />
                        <PinError>{isCodeValid === false ? 'код неверный' : ''}</PinError>
                    </PinContainer>
                    

                    {regClientStatus === 'pending'
                    ? <Spinner/>
                    : dataResult === 0
                        ? <ErrorMessage>{dataMessage}</ErrorMessage>
                        : timer > 0
                            ? (
                                <StyledButton type='primary' disabled>Получить новый код 0:{timer < 10 ? `0${timer}` : timer}</StyledButton>
                            ) : (
                                <StyledButton type='primary' onClick={handlePhoneSubmit}>Получить новый код</StyledButton>
                            )
                    }
                </>
            }
        </>
    );
};

export default AuthForm;

const StyledTitle = styled(Title)`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    color: #2A2A2A;
    @media (max-width: 748px) {
        font-size: 24px;
        font-weight: 500;
        line-height: 29.11px;
    }
`
const Text = styled.p`
    font-size: 20px;
    line-height: 22px;
    color: #2a2a2a;
    @media (max-width: 748px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 19.41px;
    }
`
const InlineGroup = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    margin: 16px 0;
`
const grow = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`
const shrink = keyframes`
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
`
const ToggleLabel = styled.label`
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ active }) => (active ? '#2a2a2a' : '#ababab')};
    transition: color 0.3s ease;
`
const RadioButton = styled.div`
    width: 32px;
    height: 32px;
    border: 1px solid ${({ active }) => (active ? '#EA394B' : '#dbdbdb')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease;

    &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-color: #EA394B;
        border-radius: 50%;
        transform: scale(${({ active }) => (active ? 1 : 0)});
        animation: ${({ active }) => (active ? grow : shrink)} 0.3s ease-out;
    }
`
const StyledPhoneInput = styled(InputMask)`
    margin-top: 24px;
    width: 100%;
    height: 60px;
    padding: 0 32px;
    background: #F9F9F9;
    border: 1px solid #EAEAEA;
    border-radius: 16px;
    font-size: 24px;
    outline: none;
`
const PinContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 16px;
`
const PinError = styled.div`
    height: 16px;
    color: #EA394B;
`
const TermsLink = styled.a`
    margin-top: 12px;
    display: block;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.44px;
    text-align: center;
    color: #B2B2B2;
    span {
        text-decoration: underline;
        color: #2A2A2A;
    }
    &:hover {
        color: #B2B2B2;
        text-decoration: none;
    }
`
const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 24px;
`
const ErrorMessage = styled.div`
    font-size: 20px;
    line-height: 24px;
    color: #EA394B;
    text-align: center;
`