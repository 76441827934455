import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sha256 } from '../../../utils/sha256';
import { request } from '../../../utils/request';

export const sendPhone = createAsyncThunk('auth/sendPhone', async (phone, {rejectWithValue }) => {
    try {
        let response = await request("ClientAuthService", "SendCodeToPhone", {
            phone
        });

        if (response.result) {
            return {
                phone: phone,
                session: response.data.session,
            };
        } else {
            return rejectWithValue('Ошибка извлечения данных');
        }
    } catch (error) {
        console.error(error);
        return rejectWithValue('Ошибка подключения');
    }
});

export const sendCode = createAsyncThunk('auth/sendCode', async (code, {rejectWithValue }) => {
    try {
        localStorage.setItem("codeHash", sha256(code));
        let response = await request("ClientAuthService", "CreateTokenBySession");

        if (response.result) {
            return {
                token: response.data.token,
                refreshToken: response.data.refreshToken,
            };
        } else {
            return rejectWithValue('Ошибка извлечения данных');
        }
    } catch (error) {
        console.error(error);
        return rejectWithValue('Ошибка подключения');
    }
});

export const regClient = createAsyncThunk('auth/regClient', async (_, {getState, rejectWithValue }) => {
    const state = getState();
    const userCurrentCityId = state.interactiveMapPromo.common.userCurrentCityId;
    const userPhone = state.interactiveMapPromo.auth.userPhone;
    const addressObject = state.interactiveMapPromo.map.addressGeocodeObject;
    const aditionalAddressObject = state.interactiveMapPromo.map.aditionalAddressObject;

    const data = {
        token: localStorage.getItem("token") || "",
        session: localStorage.getItem("session") || "",
        phone: userPhone,
        address:{
            city: userCurrentCityId,
            ...addressObject,
            ...aditionalAddressObject,
        },
    }

    try {
        let response = await request("PromoActionStart", "RegClientPromo", data);

        return response;
    } catch (error) {
        console.error(error);
        return rejectWithValue('Ошибка подключения');
    }
});

const initialState = {
    sendPhoneStatus: null,
    userPhone: null,

    sendCodeStatus: null,

    regClientStatus: null,
    dataResult: null,
    dataMessage: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthState: (state) => {
            state.sendPhoneStatus = null;
            state.userPhone = null;
            state.sendCodeStatus = null;
            state.regClientStatus = null;
            state.dataResult = null;
            state.dataMessage = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // sendPhone
            .addCase(sendPhone.pending, (state) => {
                state.sendPhoneStatus = 'pending';
            })
            .addCase(sendPhone.fulfilled, (state, action) => {
                state.sendPhoneStatus = 'fulfilled';
                state.userPhone = action.payload.phone;
                localStorage.setItem("loginHash", sha256(action.payload.phone));
                localStorage.setItem('session', action.payload.session);
            })
            .addCase(sendPhone.rejected, (state) => {
                state.sendPhoneStatus = 'rejected';
            })

            // sendCode
            .addCase(sendCode.pending, (state) => {
                state.sendCodeStatus = 'pending';
            })
            .addCase(sendCode.fulfilled, (state, action) => {
                state.sendCodeStatus = 'fulfilled';
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem("refreshToken", action.payload.refreshToken);
            })
            .addCase(sendCode.rejected, (state) => {
                state.sendCodeStatus = 'rejected';
            })

            //regClient
            .addCase(regClient.pending, (state) => {
                state.regClientStatus = 'pending';
            })
            .addCase(regClient.fulfilled, (state, action) => {
                state.regClientStatus = 'fulfilled';
                state.dataResult = action.payload.result;
                state.dataMessage = action.payload.data ? action.payload.data.message : action.payload.message;
            })
            .addCase(regClient.rejected, (state) => {
                state.regClientStatus = 'rejected';
            })
    },
});

export const {
    resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
