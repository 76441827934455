import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import PageHeader from "../PageHeader";
import {NAME_COMPANY} from "../../utils/constants";

class NotFound extends Component {
	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Страница не найдена</title>
				</Helmet>
			</Fragment>
		);
	}
}

export default NotFound;
