import React, {Fragment, PureComponent} from "react";
import {NavLink, withRouter} from "react-router-dom";
import styled from "styled-components";
import navIcon from "../../../images/burger.svg";
import {_} from "underscore";
import {LIGHT_FONT, MAIN_COLOR, TXT_COLOR} from "../../../utils/constants";
import {SelectAddress} from "../../Menu/SelectAddress";
import {connect} from "react-redux";

class MainNav extends PureComponent {
	toggleMobileMenu = () => {
		let closeBut = this.crossButton.className;

		if (window.matchMedia("(max-width: 992px)").matches) {
			let nav = this.navNode;
			let shadBlock = this.shadowBlock;

			if (nav.style.left === "-100%") {
				nav.style.left = "0";
				nav.style.opacity = "1";
				shadBlock.style.zIndex = "5000";
				shadBlock.style.opacity = "1";
				shadBlock.style.visibility = "visible";
			} else if (nav.style.left === "0px") {
				nav.style.left = "-100%";
				nav.style.opacity = "0";
				shadBlock.style.zIndex = "-1";
				shadBlock.style.opacity = "0";
				shadBlock.style.visibility = "hidden";
			} else {
				nav.style.left = "0";
				nav.style.opacity = "1";
				shadBlock.style.zIndex = "5000";
				shadBlock.style.opacity = "1";
				shadBlock.style.visibility = "visible";
			}
		}

		if(closeBut.indexOf('active') !== -1 && closeBut.indexOf('back') === -1){
			this.crossButton.className = this.crossButton.className + ' back';
		} else if (closeBut.indexOf('back') !== -1) {
			this.crossButton.className = _.without(this.crossButton.className.split(' '), 'back').join(' ');
		} else {
			this.crossButton.className = this.crossButton.className + ' active';
		}
	};

	render() {
		let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
		let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

		return (
			<Fragment>
				<Nav ref={node => (this.navNode = node)} fixedMenu={this.props.fixedMenu}>
					{this.props.isAuth && this.props.widthScreen <= 992 && (
						<SelectAddress
							companies={this.props.companies}
							activeCompany={this.props.activeCompany}
							saveCompany={this.props.saveCompany}
							handleReduxFormEditCompany={this.props.handleReduxFormEditCompany}
							valueEditCompany={this.props.valueEditCompany}
							cities={this.props.cities}
							initialValuesEditCompanyAction={this.props.initialValuesEditCompanyAction}
							changeActiveCompany={this.props.changeActiveCompany}
							getRemoveInviteToCompany={this.props.getRemoveInviteToCompany}
							confirmInviteToCompany={this.props.confirmInviteToCompany}
							profile={this.props.profile}
							openSelect={this.props.openSelectAddress}
							changeOpenSelect={this.props.changeOpenSelect}
							deliveryCycleInfoMessage={this.props.deliveryCycleInfoMessage}
							day={this.props.day}
							period={this.props.period}
							getAccountsForData={this.props.getAccountsForDate}
							changeInformationView={this.props.changeInformationView}
							viewInfoPerformance={this.props.viewInfoPerformance}
							infoViewToggle={this.props.infoViewToggle}
							getMenu={this.props.getMenu}
						/>
					)}
					<NavLinked exact to="/" onClick={this.toggleMobileMenu}>
						Меню
					</NavLinked>
					<NavLinked exact to="/delivery" onClick={this.toggleMobileMenu}>
						Доставка
					</NavLinked>
					<NavLinked exact to="/pay" onClick={this.toggleMobileMenu}>
						Оплата
					</NavLinked>
					<NavLinked exact to="/about" onClick={this.toggleMobileMenu}>
						О нас
					</NavLinked>
                    {/*<NavLinked exact to="/event" onClick={this.toggleMobileMenu}>*/}
                        {/*Акции*/}
                    {/*</NavLinked>*/}
					{(!is51obed && !isStraus) && (
						<NavLinked exact to="/manager" onClick={this.toggleMobileMenu}>
							Компаниям
						</NavLinked>
					)}
					{(!is51obed && !isStraus) && (
						<CorsLink href="https://partner.sitiy.ru" target="_blank" style={{width: 52}}>
							Кухням
						</CorsLink>
					)}
					{/*{(!is51obed && !isStraus) && (*/}
					{/*	<NavLinked exact to="/promo" onClick={this.toggleMobileMenu}>*/}
					{/*		Промокод*/}
					{/*	</NavLinked>*/}
					{/*)}*/}
					{
						this.props.isAuth && (
							<NavLinked exact to="/my-orders" onClick={this.toggleMobileMenu}>
								Мои заказы
							</NavLinked>
						)
					}
				</Nav>
				<ShadowBlock ref={prop => (this.shadowBlock = prop)}/>
				<NavCrossButton className="menu-animate-cross" ref={nav => (this.crossButton = nav)} onClick={this.toggleMobileMenu}>
					<span className="bar"></span>
					<span className="bar"></span>
				</NavCrossButton>
			</Fragment>
		);
	}
}
const mapStateToProps = store => ({
	isAuth: store.profile.isAuth,
});

export default connect(
	mapStateToProps
)(withRouter(MainNav));

const Nav = styled.nav`
	display: flex;
	//justify-content: ${props => props.fixedMenu != null ? props.fixedMenu ? 'center' : 'center' : 'end'};
	z-index: 1002;
	transition: .3s cubic-bezier(1, -0.01, 0.65, 0.63);

	@media (max-width: 992px) {
		position: absolute;
		left: -100%;
		top: 54px; //60px
		width: 100%;
		height: calc(100vh - 44px);
		background: #fff;
		flex-direction: column;
		z-index: 9000;
		justify-content: center;
		opacity: 0;
	}
`;

export const NavLinked = styled(NavLink)`
	${LIGHT_FONT};
	color: ${TXT_COLOR};
	transition: all 0.15s ease;
	margin: 0 30px 0 0;
	flex-shrink: 0;
	font-size: 13px;

	&:hover,
	&.active {
		text-decoration: none;
		color: ${MAIN_COLOR};
	}

	@media (max-width: 992px) {
		font-size: 26px;
		margin: 15px;
	}
`;

const NavLinkedWithNotificator = styled(NavLinked)`
	position: relative;
	> span {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: -15px;
		right: -17px;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background: linear-gradient(42.27deg, #6A11CB 13.42%, #2575FC 85.17%);
		font-size: 12px;
		line-height: 14px;
		color: #fff;
	}
`
const CorsLink = styled.a`
	${LIGHT_FONT};
	color: ${TXT_COLOR};
	transition: all 0.15s ease;
	margin: 0 30px 0 0;
	flex-shrink: 0;
	font-size: 13px;

	&:hover,
	&.active {
		text-decoration: none;
		color: ${MAIN_COLOR};
	}

	@media (max-width: 992px) {
		font-size: 26px;
		margin: 15px;
	}
`
const NavIcon = styled.span`
	background: url(${navIcon});
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	height: 25px;
	width: 25px;
	margin-left: 25px;
	margin-top: 5px;

	@media (min-width: 992px) {
		display: none;
	}
`;

const NavCrossButton = styled.div`
	@media (min-width: 992px) {
		display: none;
	}
`;

const ShadowBlock = styled.div`
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	transition: .3s cubic-bezier(1, -0.01, 0.65, 0.63);
	margin-top: 56px;
	visibility: hidden;
`;

const StarImg = styled.img`
	
`;
