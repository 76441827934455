import React from "react";
import GoogleMaps from "../../../GoogleMap"
import styled from "styled-components";

const ModalRegStageFirst = ({children, isMobile, customerAddress}) => {

  return (
    <Wrapper>
      <Header>
        {children}
      </Header>
      <GoogleMaps style={isMobile && ({height: 350})} isMobile={isMobile} defaultData={customerAddress}/>
    </Wrapper>
  )
}

export default ModalRegStageFirst;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
`
