import React, {Fragment, useEffect, useState} from "react";
import * as _ from "underscore";
import styled from "styled-components";
import ModalDeleteAll from "../../CheckList/ModalDeleteAll";
import btnPreloader from "../../../images/button-preloader.svg";
import {
    MAIN_COLOR,
    ORDER_STATUS_COMPLETE,
    ORDER_STATUS_IS_DELIVERING,
    ORDER_STATUS_PREPARING
} from "../../../utils/constants";
import Preloader from "../../Preloader";
import CountUp from "react-countup";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimplePopover} from "../../Modal/PayPopover";
import checkClose from "../../../images/close-nav-cross.svg";
import orderGreen from "../../../images/order-green.svg";
import deliveryMan from "../../../images/delivery-man.svg";
import {NavLink, withRouter} from "react-router-dom";

const Check = (props) => {
    let statusOrder = '';
    let sumAll = 0;

    switch(props.companyOrder && props.companyOrder.internalStatus){
        case ORDER_STATUS_PREPARING: statusOrder = `Ваш заказ готовится`;
            break;
        case ORDER_STATUS_IS_DELIVERING: statusOrder = `Ваш заказ доставляется`;
            break;
        case ORDER_STATUS_COMPLETE: statusOrder = `Ваш заказ доставлен`;
            break;
        default: statusOrder = statusOrder = `Ваш заказ принят`;
    }

    const onEditOrder = () => {
        props.changeWidget('edit');
        props.editToCart();
    };

    _.each(props.regOrder, item => {
         _.each(item.products, prod => {
             if (_.size(prod.modifiers) === 0) {
                 sumAll += parseInt(prod.price) * parseInt(prod.amount);
             } else {
                 sumAll += parseInt(prod.price) * parseInt(prod.amount) + parseInt(prod.modifiers[0].price) * parseInt(prod.amount);
             }
         });
    });

    return (
        <EditWrapper open={props.openBasket} widget={props.check} id="check">
            <BasketPanelUser>
                <BasketHeader
                    // onTouchEnd={(e)=>{props.getTouchEnd(e)}}
                    // onTouchMove={(e)=>{props.getTouchMove(e)}}
                    // onTouchStart={(e)=>{props.getTouchStart(e)}}
                >
                    {props.widgetPreloader ? (
                        <Preloader message={false} paddingTop={12}/>
                    ) : (
                        <Fragment>
                            {props.isMobile ? (
                                <HeadLeft>
                                    <CheckClose onClick={props.closeCart}>
                                        <img src={checkClose} width={12} alt="Закрыть"/>
                                    </CheckClose>
                                    <NameBasket>
                                        <BasketTitle>
                                            Чек
                                        </BasketTitle>
                                    </NameBasket>
                                    {props.timeStatus !== -1 && !_.some(props.regOrder, order => {return order.paymentStatus === "2"}) && (
                                        <ActionIcons>
                                            <ModalDeleteAll
                                                getModalToggle={props.getModalToggle}
                                                saveOrder={props.saveOrder}
                                                getSum={props.getSum}
                                                getCheckEdit={props.getCheckEdit}
                                                getOrders={props.getOrders}
                                                payStatus={props.payStatus}
                                                day={props.day}
                                                restaurants={props.restaurants}
                                                infoMessRemove={props.infoMessRemove}
                                                regOrder={props.regOrder}
                                                activePeriod={props.activePeriod}
                                                isMobile={props.isMobile}
                                            />
                                        </ActionIcons>
                                    )}
                                </HeadLeft>
                            ) : (
                                <HeadLeft>
                                    <NameBasket>
                                        {statusOrder}
                                        <BottomIcon>
                                            <IconSvg width="12" height="10" viewBox="0 0 12 10" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.21628 6.58629C6.61458 6.98459 6.61458 7.66738 6.21628 8.06568L5.39124 8.89072C4.99294 9.28902 4.31015 9.28902 3.91185 8.89072L0.298723 5.24915C-0.0995743 4.85085 -0.0995743 4.16806 0.298723 3.76976L1.12377 2.94472C1.52206 2.54642 2.20486 2.54642 2.60316 2.94472L6.21628 6.58629Z"/>
                                                <path d="M9.39679 0.298723C9.79508 -0.0995743 10.4779 -0.0995743 10.8762 0.298723L11.7012 1.12377C12.0995 1.52206 12.0995 2.20486 11.7012 2.60316L5.44227 8.83366C5.04397 9.23196 4.36118 9.23196 3.96288 8.83366L3.13783 8.00862C2.73954 7.61032 2.73954 6.92753 3.13783 6.52923L9.39679 0.298723Z"/>
                                            </IconSvg>
                                        </BottomIcon>
                                    </NameBasket>
                                    {props.timeStatus !== -1 && !_.some(props.regOrder, order => {return order.paymentStatus === "2"}) && (
                                        <ActionIcons>
                                            <EditOrder icon={faEdit} onClick={()=>{onEditOrder()}}/>
                                            <ModalDeleteAll
                                                getModalToggle={props.getModalToggle}
                                                saveOrder={props.saveOrder}
                                                getSum={props.getSum}
                                                getCheckEdit={props.getCheckEdit}
                                                getOrders={props.getOrders}
                                                payStatus={props.payStatus}
                                                day={props.day}
                                                restaurants={props.restaurants}
                                                infoMessRemove={props.infoMessRemove}
                                                regOrder={props.regOrder}
                                                activePeriod={props.activePeriod}
                                            />
                                        </ActionIcons>
                                    )}
                                </HeadLeft>
                            )}
                            {props.timeStatus !== -1 && !_.some(props.regOrder, order => {return order.paymentStatus === "2"}) &&  (
                                <ButtonAction onClick={()=>{onEditOrder()}}>Изменить</ButtonAction>
                            )}
                        </Fragment>
                    )}
                </BasketHeader>
                <BasketBody>
                    {props.widgetPreloader || _.size(props.regOrder) === 0 ? (
                        <TimePreloader src={btnPreloader} alt=""/>
                    ) : (
                        <Fragment>
                            {props.isMobile && (
                                <OrderIsProcessedBox>
                                    <img src={orderGreen} alt="Заказ оформлен"/>
                                    <OrderIsProcessedText>Заказ оформлен</OrderIsProcessedText>
                                </OrderIsProcessedBox>
                            )}
                            {_.map(props.regOrder, (rest_order, key_rest)=>{
                                return(
                                    <Card key={key_rest}>
                                        <OrderWrapper paid={rest_order.paymentStatus}>
                                            <Fragment>
                                                <RestInfo>
                                                    <RestImage src={props.restarauntsInfo[rest_order && rest_order.account].logo}/>
                                                    <RestName>{props.restarauntsInfo[rest_order.account].name}</RestName>
                                                </RestInfo>
                                            </Fragment>
                                            {_.map(rest_order.products, (order, key_order)=>
                                                <Order key={key_order}>
                                                    <OrderName>
                                                        <Name>{_.size(order.modifiers) === 0 ? order.name : order.name + " " + order.modifiers[0].name}</Name>
                                                    </OrderName>
                                                    {props.isMobile ? (
                                                        <OrderCountAndPrice>
                                                            <OrderCount>x{_.size(order.modifiers) === 0 ? order.amount : order.amount}</OrderCount>
                                                            <OrderPrice>{_.size(order.modifiers) === 0
                                                                ? parseInt(order.price)
                                                                : parseInt(order.modifiers[0].price) + (parseInt(order.amount) * parseInt(order.price))} ₽</OrderPrice>
                                                        </OrderCountAndPrice>
                                                    ) : (
                                                        <Fragment>
                                                            <OrderCount>x{_.size(order.modifiers) === 0 ? order.amount : order.amount}</OrderCount>
                                                            <OrderPrice>{_.size(order.modifiers) === 0
                                                                ? parseInt(order.price)
                                                                : parseInt(order.modifiers[0].price) + (parseInt(order.amount) * parseInt(order.price))} ₽</OrderPrice>
                                                        </Fragment>
                                                    )}
                                                </Order>
                                            )}

                                            {rest_order.paymentStatus !== "2" && rest_order.paysystem.id !== '32' ? [
                                                <P12>Способ оплаты</P12>,
                                                <SimplePopover
                                                    restInfo={props.restarauntsInfo[rest_order.account]}
                                                    orderId={rest_order.id}
                                                    payLoading={props.payLoading}
                                                    priceResult={rest_order.priceResult}
                                                    payStatus={rest_order.paymentStatus}
                                                    day={props.day}
                                                    getAccountsForDate={props.getAccountsForDate}
                                                    paymentStatus={rest_order.paymentStatus}
                                                    products={rest_order.products}
                                                    regOrder={props.regOrder}
                                                    activePeriod={props.activePeriod}
                                                    isMobile={props.isMobile}
                                                />
                                            ] : (
                                                <Button color="primary">
                                                    <Text>Заказ оплачен</Text>
                                                </Button>
                                            )}
                                        </OrderWrapper>
                                    </Card>
                                )})}
                            {props.isMobile && (
                                <Fragment>
                                    <DeliveryBox>
                                        <DeliveryLeft>
                                            <img src={deliveryMan} alt="Доставка"/>
                                            <DeliveryPeriodText>Доставка с {props.periods[1]} до {props.periods[2]}</DeliveryPeriodText>
                                        </DeliveryLeft>
                                        <DeliveryPrice>{Math.round(props.companyOrder.deliveryPrice || 0)} ₽</DeliveryPrice>
                                    </DeliveryBox>
                                    <ResultBox>
                                        <ResultLeftText>Сумма заказа</ResultLeftText>
                                        <ResultRightPrice>{sumAll} ₽</ResultRightPrice>
                                    </ResultBox>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </BasketBody>
                {props.isMobile ? (
                    <BasketFooter openBasket={props.openBasket}>
                        <NavLink to="/" onClick={props.closeCart}>
                            <ToMain>На главную</ToMain>
                        </NavLink>
                    </BasketFooter>
                ) : (
                    <BasketFooter openBasket={props.openBasket}>
                        <Result>
                            <ResultCell>
                                <ResultName>
                                    Доставка:
                                </ResultName>
                                <ResultPrice>{Math.round(props.companyOrder.deliveryPrice || 0)} р.</ResultPrice>
                            </ResultCell>
                        </Result>
                        <ResultAll>
                            <ResultCell>
                                <ResultNameAll>
                                    Сумма заказа:
                                </ResultNameAll>
                                <ResultPriceAll>
                                    <CountUp end={parseInt(sumAll || 0)} duration={0.75} redraw={true}>
                                        {({ countUpRef }) => (
                                            <TextCount ref={countUpRef}/>
                                        )}
                                    </CountUp> р.
                                </ResultPriceAll>
                            </ResultCell>
                        </ResultAll>
                    </BasketFooter>
                )}
            </BasketPanelUser>
        </EditWrapper>
    )
};

export default withRouter(Check);

const Button = styled.div`
    width: 100%;
    margin-right: 12px;
`;

const Text = styled.span`
    color: #1DD960;
    font-family: "SF Display";
    font-weight: bold;
    font-size: 14px;
`;

const TimePreloader = styled.img`
    width: 100px;
    -webkit-transition: .3s;
    transition: .3s;
    margin: 82px auto 0;
    display: block;
`;
const BasketPanelUser = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const OrderCount = styled.div`
  width: 10%;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  
  @media (max-width: 992px) {
      width: auto;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      line-height: 15px;
      font-weight: normal;
      text-align: right;
      color: #263238;
  }
`;
const ResultName = styled.div`
  width: 50%;
  white-space: nowrap;
  color: #fff;
`;
const ResultNameAll = styled(ResultName)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;
const OrderName = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #fff;
  
  @media (max-width: 992px) {
    width: 78%;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
  }
`;
const OrderPrice = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 14px;
  color: #fff;
  
  @media (max-width: 992px) {
      width: auto;
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      line-height: 15px;
      font-weight: normal;
      text-align: right;
      margin-left: 5px;
      color: #263238;
  }
`;
const ResultPrice = styled(OrderPrice)`
  width: 50%;
`;
const ResultPricePayType = styled(ResultPrice)`
  justify-content: end;
  text-decoration: dotted underline rgba(255, 255, 255, 0.3);
  cursor: pointer;
  position: relative;
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  margin-left: 10px;
`;
const ResultPriceAll = styled(ResultPrice)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;
const TextCount = styled.span``;
const Order = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;
const Result = styled.div`
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
`;
const ResultCell = styled.div`
  display: flex;
  justify-content: space-between;
  transition: .3s linear;
  
    &.payButton-enter {
      opacity: 0;
      transform: scale(0.9) translate(0, 50px);
      max-height: 0;
      transition: max-height .3s linear;
    }
    
    &.payButton-enter-active {
      opacity: 1;
      transform: translateX(0);
      max-height: 50px;
      transition: max-height 300ms, opacity 300ms, transform 300ms;
    }
    
    &.payButton-exit {
      opacity: 1;
    }
    
    &.payButton-exit-active {
      opacity: 0;
      transform: scale(0.9) translate(0, -100%);
      transition: all 300ms linear;
      margin-top: -50px;
    }
`;
const PayButton = styled.div`
  width: 100%;
  margin: 5px 20px;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #223;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
`;
const ResultAll = styled(Result)`
  border-bottom: none;
`;
const Name = styled.p`
  margin-bottom: 0;
`;
const EditWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: #37474F;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transform: ${({widget}) => widget ? 'rotateY(0deg)' : 'rotateY(-180deg)'};
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s linear;
    z-index: ${({widget}) => widget ? '2' : '1'};
    flex-direction: column;
    opacity: ${({widget}) => widget ? '1' : '0'};
    
    @media(min-width: 992.01px) {
        &:before{
            width: 104px;
            height: 104px;
            background: linear-gradient(to bottom,rgba(55,71,79,1) 30%,rgba(224,213,210,0) 20%,rgba(237,237,237,0) 100%);
            border-radius: 50%;
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            top: -20px;
            z-index: 0;
        }
    }
    
    @media(max-width: 992px){
        /*border-radius: ${({open}) => open ? "0" : "6px 6px 0 0"};*/
        border-radius: 0;
        background: #ffffff;
        box-shadow: none;
    }
`;
const BasketHeader = styled.div`
    min-height: 58px;
    background: #37474F;
    display: flex;
    transform: rotateY(0deg);
    z-index: 2;
    margin: 0 15px;
    padding: 10px 0;
    flex-direction: column;
    justify-content: center;
    
    @media(max-width: 992px){
        min-height: auto;
        height: 50px;
        background: #ffffff;
        margin: 0;
        padding: 3px 7.44px 3px 1px;
    }
`;
const BasketBody = styled.div`
  flex-grow: 1;
  padding: 13px 0 0 0;
  margin: 0 13px;
  border: 2px dotted rgba(255, 255, 255, 0.3);
  border-right: none;
  border-left: none;
  overflow: auto;
  height: calc(100% - 160px);
  
  @media (min-width: 992.01px) {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f7efef;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #a5a2a2;
        border-radius: 5px;
    }
  }
  
  @media (max-width: 992px) {
    margin: 0 12px;
    border: none;
    height: calc(100% - 110px);
  }
`;
const OrderWrapper = styled.div`
    position: relative;
`;
const Card = styled.div`
    position: relative;
    margin-bottom: 15px;
    
  @media (max-width: 992px) {
    margin-bottom: 0px;
    padding-bottom: 32px;
    border-bottom: 1px solid #E6E9F0;
  }
`;
const RestInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    
    @media (max-width: 992px) {
        padding: 30px 0 6px;
    }
`;
const RestImage = styled.img`
    width: 32px;
    
    @media (max-width: 992px) {
        width: 26px;
        height: 26px;
    }
`;
const RestName = styled.span`
    color: #fff;
    font-size: 13px;
    font-family: "SF Display";
    font-weight: bold;
    margin-left: 15px;
    
    @media (max-width: 992px) {
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #263238;
        margin-left: 4px;
    }
`;
const BasketFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: ${({openBasket}) => !openBasket ? 'opacity .1s cubic-bezier(0.56, 0.01, 0.26, 0.26)' : 'opacity .7s cubic-bezier(0.56, 0.01, 0.26, 0.26)'};
    z-index: ${({openBasket}) => openBasket ? '1' : '-1'};
    opacity: ${({openBasket}) => openBasket ? '1' : '0'};
    padding: 0 15px;
    background: #37474F;
    border-radius: 0 0 6px 6px;
    
    @media(max-width: 992px){
      height: 60px;
      padding: 9px 12px;
      background: #ffffff;
      border-radius: 0;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    }
`;
const BottomIcon = styled.div`
    padding-left: 10px;
    fill: #fff;
    stroke: #fff;
    transition: .3s linear;
`;
const IconSvg = styled.svg`
    fill: ${MAIN_COLOR};
    stroke: ${MAIN_COLOR};
`;
const NameBasket = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 900;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    
    @media (min-width: 992.01px) {
        &:hover {
          text-shadow: 0 0 8px #fff;
        }
        &:hover ${BottomIcon} {
            filter: drop-shadow( 0 0 8px ${MAIN_COLOR});
        }
    }
    
    @media (max-width: 992px) {
        width: calc(100% - 88px);
        justify-content: center;
    }
`;
const ActionIcons = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    
    @media (max-width: 992px) {
        margin: 0;
    }
`;
const EditOrder = styled(FontAwesomeIcon)`
    color: ${MAIN_COLOR};
    font-size: 22px;
    margin-left: 27px;
    
    @media(min-width:992px){
        display: none;
    }
`;
const HeadLeft = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    width: 100%;
    z-index: 1;
    transition: .2s ease-out;
    cursor: pointer;
`;
const ButtonAction = styled.div`
    color: #fff;
    font-size: 14px;
    text-decoration: dotted underline rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    
    @media(max-width: 992px){
        display: none;
    }
`;

const BlurLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
`;

const BlurLayerPaid = styled.div`
    border: 2px solid ${MAIN_COLOR};
    border-radius: 12px;
    padding: 0 12px;
    font-size: 28px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 0;
    bottom: 0;
    height: 48px;
    width: 80%;
    color: ${MAIN_COLOR};
    z-index: 5;
`;

const P12 = styled.p`
    margin-top: 7px;
    margin-bottom: 9px;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    
    @media(max-width: 992px){
        margin-top: 4px;
        margin-bottom: 12px;
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #263238;
    }
`;

const CheckClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
`;

const BasketTitle = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
    margin: 0;
`;

const OrderIsProcessedBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 20px 17px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(29, 25, 57, 0.15);
    border-radius: 10px;
`

const OrderIsProcessedText = styled.p`
    margin: 0 0 0 14px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #222222;
`

const OrderCountAndPrice = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 22%;
    padding-top: 2px;
`

const ToMain = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    height: 42px;
    background: ${MAIN_COLOR};
    
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    border-radius: 666px;
`

const DeliveryBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 19px 4px 26px 0;
    border-bottom: 1px solid #e6e9f0;
`

const ResultBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 26px 4px 26px 0;
`

const DeliveryLeft = styled.div`
    display: flex;
`

const DeliveryPeriodText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 4px 0 0 9px;
`

const ResultLeftText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 0;
`

const DeliveryPrice = styled.p`
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 4px 4px 0 0;
`

const ResultRightPrice = styled.p`
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #263238;
    margin: 0 4px 0 0;
`