import React from "react";
import { useDispatch } from 'react-redux';
import { decrementMapZoom, incrementMapZoom } from '../../../features/utils/mapReducer';
import styled from "styled-components";
import { MinusIcon, PlusIcon } from '../../../components/Icons';
import Button from "../../../components/Button";

const MapZoomControl = () => {
    const dispatch = useDispatch();

    const handlePlusZoom = () => {
        dispatch(incrementMapZoom());
    }

    const handleMinusZoom = () => {
        dispatch(decrementMapZoom());
    }

    return (
        <Wrapper>
            <Button type='icon' onClick={handlePlusZoom}>
                <PlusIcon/>
            </Button>

            <Button type='icon' onClick={handleMinusZoom}>
                <MinusIcon/>
            </Button>
        </Wrapper>
    )
}

export default MapZoomControl;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media (max-width: 748px) {
        margin-right: 16px;
    }
`
