import { combineReducers } from 'redux';
import authReducer from './utils/authReducer';
import commonReducer from './utils/commonReducer';
import mapReducer from './utils/mapReducer';

const interactiveMapPromoReducer = combineReducers({
    auth: authReducer,
    common: commonReducer,
    map: mapReducer,
});

export default interactiveMapPromoReducer;