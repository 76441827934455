import { useEffect, useState } from 'react';

const useDeviceOS = () => {
  const [os, setOS] = useState('unknown');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      setOS('Windows Phone');
    } else if (/android/i.test(userAgent)) {
      setOS('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOS('iOS');
    } else if (/Win/i.test(userAgent)) {
      setOS('Windows');
    } else if (/Mac/i.test(userAgent)) {
      setOS('MacOS');
    }
  }, []);

  return os;
};

export default useDeviceOS;
