import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ReactModal from "react-modal";
import styled from "styled-components";
import Backdrop from '@material-ui/core/Backdrop';
import {useSpring, animated} from 'react-spring/web.cjs';
import {useDispatch, useSelector} from "react-redux";
import {RadioButtonsGroup} from "../../Groups/RadioGroups";
import {payCards} from "../../../actions/PersonalAction";
import cartBack from "../../../images/cart-back.svg";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

export const PayCardsModal = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModalAdd = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setOpenModal(!openModal);
    };

    return (
        <Fragment>
            <PayType onClick={() => {setOpenModal(true)}}>{props.namePayType('16')}</PayType>
            {props.isMobile ? (
                <ReactModal
                    closeTimeoutMS={100}
                    isOpen={openModal}
                    style={customStylesMobile}
                    overlayClassName={"Modifiers__Overlay"}
                    onRequestClose={(e)=>{handleOpenModalAdd(e)}}
                >
                    <WrapperModal>
                        <ModalHeader>
                            <ModalBack onClick={(e)=>{handleOpenModalAdd(e)}}>
                                <img src={cartBack} alt="Закрыть"/>
                            </ModalBack>
                            <PaymentByPointsText>Оплата онлайн</PaymentByPointsText>
                        </ModalHeader>
                        <RadioButtonsGroup
                            payCards={props.payCards}
                            orderId={props.orderId}
                            priceResult={props.priceResult}
                            cards={props.cards}
                            toggleModal={setOpenModal}
                            setOpenPopover={props.setOpenPopover}
                            restId={props.restId}
                            removeFromCard={props.removeFromCard()}
                            isMobile={props.isMobile}
                        />
                    </WrapperModal>
                </ReactModal>
            ) : (
                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    className={classes.modal}
                    open={openModal}
                    onClose={() => {setOpenModal(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal} style={{outline: 0, border: 0}}>
                        <WrapperModal>
                            <RadioButtonsGroup
                                payCards={props.payCards}
                                orderId={props.orderId}
                                priceResult={props.priceResult}
                                cards={props.cards}
                                toggleModal={setOpenModal}
                                setOpenPopover={props.setOpenPopover}
                                restId={props.restId}
                                removeFromCard={props.removeFromCard()}
                            />
                        </WrapperModal>
                    </Fade>
                </Modal>
            )}
        </Fragment>
    );
};

const customStylesMobile = {
    content: {
        top: 0,
        left: 0,
        right: "auto",
        bottom: "auto",
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        borderRadius: 0,
        cursor: "auto",
        width: "100%",
        height: "100%",
    }
};

const WrapperModal = styled.div`
    width: 650px;
    background: #fff;
    border-radius: 26px;
    outline: none;
    padding: 32px;
    
    @media (max-width: 992px) {
        width: 100%;
        min-height: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        border-radius: 0;
        background: #fff;
        overflow: hidden;
    }
`;

const PayType = styled.div`
    padding: 4px 0;
    cursor: pointer;
    //width: 100%;
    width: calc((100% - 4px) / 2);
    background: #fff;
    color: #37474F;
    text-align: center;
    border-radius: 66px;
    border: 1px solid #37474F;
    font-size: 12px;
    line-height: 18px;
  
  @media (min-width: 992.01px) {
      &:hover{
        background: #37474F;
        color: #fff;
        border: 1px solid #fff;
      }
  }
  
  @media (max-width: 992px) {
    padding: 9px 0;
    width: calc((100% - 16px) / 2);
    background: #CDDC39;
    color: #263238;
    text-align: center;
    border-radius: 66px;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 18px;
  }
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 3px 4.5px;
`;

const ModalBack = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
`;

const PaymentByPointsText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
    margin: 0;
    text-align: center;
    width: calc(100% - 88px);
    height: 18px;
`;