import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGeocodeCoords, setGeocodeLoading, setIsInsidePolygon, setMapZoom } from '../../../features/utils/mapReducer';
import { YMaps, Map, Polygon, Circle } from 'react-yandex-maps';
import styled from 'styled-components';

const MapContainer = () => {
    const dispatch = useDispatch();
    const { mapCenter, mapZoom, deliveryPolygons, isHouse, geocodeCoords, isInsidePolygon } = useSelector((state) => state.interactiveMapPromo.map);
    let mapInstance = null;
    const mapRef = useRef(null);
    const polygonRef = useRef(null);
    const debounceTimerRef = useRef(null);
    const [dragging, setDragging] = useState(false);
    const [isUserDragging, setIsUserDragging] = useState(false);
    const [isProgrammaticChange, setIsProgrammaticChange] = useState(false);
    const radius = 40; // Радиус в метрах + расплытие 20метров

    useEffect(() => {
        if (mapInstance) {
            mapInstance.setCenter(mapCenter, mapZoom);
        }
    }, [mapCenter]);

    useEffect(() => {
        if (mapInstance) {
            mapInstance.setZoom(mapZoom, { smooth: true });
            dispatch(setGeocodeLoading(false));
        }
    }, [mapZoom]);

    useEffect(() => {
        if (!mapRef.current) return;

        if (mapRef.current && geocodeCoords) {
            setIsProgrammaticChange(true);
            mapRef.current.panTo(geocodeCoords, { delay: 0, flying: true });
        }

        const isInsideAnyPolygon = polygons.some((polygon, index) => {
            const polygonInstance = mapRef.current.geoObjects.get(index);
            return polygonInstance.geometry.contains(geocodeCoords || []);
        });

        dispatch(setIsInsidePolygon(isInsideAnyPolygon));
    }, [geocodeCoords]);

    const handleBoundsChange = (e) => {
        const newZoom = e.get('newZoom');
        dispatch(setMapZoom(newZoom));
    };

    const handleMapActionStart = () => {
        setDragging(true);
        setIsUserDragging(true);
        dispatch(setGeocodeLoading(true));
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }
    };

    const handleMapActionEnd = () => {
        if (isProgrammaticChange) {
            setDragging(false);
            setIsProgrammaticChange(false);
            setIsUserDragging(false);
            dispatch(setGeocodeLoading(false));
            return;
        }

        const newCenter = mapRef.current.getCenter();

        if (isUserDragging) {
            debounceTimerRef.current = setTimeout(() => {
                dispatch(sendGeocodeCoords(newCenter));
            }, 1000);
        }
        
        setDragging(false);
        setIsUserDragging(false);
    };

    const polygons = deliveryPolygons.map((zone) => ({
        geometry: [
            zone.coords.map(coord => [coord.lat, coord.lon]),
        ],
        options: {
            fillColor: zone.areaColor,
            strokeColor: zone.borderColor,
            strokeWidth: 2,
            strokeOpacity: 0.8,
            zIndex: 2,
        },
        properties: {
            hintContent: zone.name,
            // balloonContent: `Минимальная цена доставки: ${zone.minDeliveryPrice} руб.`,
        },
    }));

    return (
        <MapWrapper>
            <YMaps
                query={{
                    apikey: 'e119accb-b623-4fe2-9b13-6f8e128efb5f',
                    lang: 'ru_RU',
                    load: 'package.full',
                }}
            >
                <Map
                    instanceRef={(ref) => {
                        if (ref) {
                            mapRef.current = ref;
                            mapInstance = ref;
                        }
                    }}
                    defaultState={{
                        center: mapCenter,
                        zoom: mapZoom,
                        yandexMapDisablePoiInteractivity: true,
                        controls: [],
                    }}
                    options={{
                        scrollZoom: false,
                        dblClickZoom: false,
                        suppressMapOpenBlock: true,
                        tileQuality: 'low',
                    }}
                    width='100%'
                    height='100%'
                    onBoundsChange={handleBoundsChange} 
                    onActionBegin={handleMapActionStart}
                    onActionEnd={handleMapActionEnd}
                >
                    {polygons && polygons.map((polygon, index) => (
                        <Polygon
                            key={index}
                            instanceRef={(ref) => {
                                if (ref) polygonRef.current = ref;
                            }}
                            geometry={polygon.geometry}
                            options={polygon.options}
                            properties={polygon.properties}
                        />
                    ))}
                </Map>
            </YMaps>

            <CentralPlacemarkWrapper dragging={dragging}>
                <CentralPlacemarkGreen dragging={dragging} error={!isInsidePolygon || !isHouse}/>
            </CentralPlacemarkWrapper>
        </MapWrapper>
    )
}

export default MapContainer;

const CentralPlacemarkGreen = ({dragging, error}) => (
    <svg width={window.innerWidth <= 748 ? "61" : "93"} height={window.innerWidth <= 748 ? "82" : "127"}  viewBox="0 0 93 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse style={{opacity: !dragging ? 1 : 0, transition: 'all .2s'}} cx="46.5" cy="105.909" rx="5.72727" ry="1.43182" fill="#2C2E32"/>
        <g filter="url(#filter0_d_1454_9979)">
            <mask id="path-2-outside-1_1454_9979" maskUnits="userSpaceOnUse" x="13" y="18" width="67" height="90" fill="black">
                <rect fill="white" x="13" y="18" width="67" height="90"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.9318 85.7064C47.9318 84.1747 49.1417 82.9281 50.6603 82.7277C66.0909 80.6917 78 67.4866 78 51.5C78 34.103 63.897 20 46.5 20C29.103 20 15 34.103 15 51.5C15 67.4866 26.9091 80.6917 42.3397 82.7277C43.8583 82.9281 45.0682 84.1747 45.0682 85.7064L45.0682 104.477C45.0682 105.268 45.7092 105.909 46.5 105.909C47.2908 105.909 47.9318 105.268 47.9318 104.477L47.9318 85.7064Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.9318 85.7064C47.9318 84.1747 49.1417 82.9281 50.6603 82.7277C66.0909 80.6917 78 67.4866 78 51.5C78 34.103 63.897 20 46.5 20C29.103 20 15 34.103 15 51.5C15 67.4866 26.9091 80.6917 42.3397 82.7277C43.8583 82.9281 45.0682 84.1747 45.0682 85.7064L45.0682 104.477C45.0682 105.268 45.7092 105.909 46.5 105.909C47.2908 105.909 47.9318 105.268 47.9318 104.477L47.9318 85.7064Z" fill="url(#paint0_linear_1454_9979)" shape-rendering="crispEdges"/>
            <path d="M45.0682 104.477H43.6364H45.0682ZM47.9318 104.477H46.5H47.9318ZM42.3397 82.7277L42.527 81.3082L42.3397 82.7277ZM50.6603 82.7277L50.473 81.3082L50.6603 82.7277ZM47.9318 85.7064H49.3636H47.9318ZM76.5682 51.5C76.5682 66.7588 65.2008 79.365 50.473 81.3082L50.8476 84.1472C66.981 82.0185 79.4318 68.2144 79.4318 51.5H76.5682ZM46.5 21.4318C63.1062 21.4318 76.5682 34.8938 76.5682 51.5H79.4318C79.4318 33.3123 64.6877 18.5682 46.5 18.5682V21.4318ZM16.4318 51.5C16.4318 34.8938 29.8938 21.4318 46.5 21.4318V18.5682C28.3123 18.5682 13.5682 33.3123 13.5682 51.5H16.4318ZM42.527 81.3082C27.7992 79.365 16.4318 66.7588 16.4318 51.5H13.5682C13.5682 68.2144 26.019 82.0185 42.1524 84.1472L42.527 81.3082ZM46.5 104.477L46.5 85.7064H43.6364L43.6364 104.477H46.5ZM46.5 104.477V104.477H43.6364C43.6364 106.059 44.9184 107.341 46.5 107.341V104.477ZM46.5 104.477V104.477V107.341C48.0816 107.341 49.3636 106.059 49.3636 104.477H46.5ZM46.5 85.7064L46.5 104.477H49.3636V85.7064H46.5ZM42.1524 84.1472C43.0368 84.2639 43.6364 84.969 43.6364 85.7064H46.5C46.5 83.3803 44.6798 81.5922 42.527 81.3082L42.1524 84.1472ZM50.473 81.3082C48.3202 81.5922 46.5 83.3803 46.5 85.7064H49.3636C49.3636 84.969 49.9632 84.2639 50.8476 84.1472L50.473 81.3082Z" fill="url(#paint1_linear_1454_9979)" mask="url(#path-2-outside-1_1454_9979)"/>
        </g>
        <circle cx="46.4999" cy="51.5" r="12.8864" fill="white"/>
        <defs>
            <filter id="filter0_d_1454_9979" x="0.681752" y="11.4091" width="91.6365" height="114.545" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="5.72727"/>
                <feGaussianBlur stdDeviation="6.44318"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1454_9979"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1454_9979" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_1454_9979" x1="25.7386" y1="33.6023" x2="68.6932" y2="111.636" gradientUnits="userSpaceOnUse">
                <stop offset="0.0953635" stop-color={error ? "#ee6371" : "#86c370"}/>
                <stop offset="1" stop-color={error ? "#EA394B" : "#59aa3e"}/>
            </linearGradient>
            <linearGradient id="paint1_linear_1454_9979" x1="23.5909" y1="3.53409" x2="46.5" y2="70.1136" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>    
);

const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    @media (max-width: 748px) {
        height: 80%;
    }
`

const CentralPlacemarkWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, ${({dragging}) => dragging ? '-90%' : '-80%'});
    transition: all .5s;
    user-select: none;
    pointer-events: none;
    @media (max-width: 748px) {
        top: 50%;
    }
`
