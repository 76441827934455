import React from "react";
import { useDispatch } from 'react-redux';
import { setUserCurrentCityId } from '../../../features/utils/commonReducer';
import { resetMapState, setMapCenter } from '../../../features/utils/mapReducer';
import styled from "styled-components";
import { BackArrowIcon, CommunicationIcon } from '../../../components/Icons';
import Button from "../../../components/Button";
import SearchBar from "./SearchBar";

const Header = () => {
    const dispatch = useDispatch();

    const handleClickBack = () => {
        dispatch(setUserCurrentCityId(null));
        dispatch(resetMapState());
    }

    const handleClickLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Ваши координаты: ', [position.coords.latitude, position.coords.longitude]);
                    dispatch(setMapCenter([position.coords.latitude, position.coords.longitude]));
                },
                (error) => {
                    console.error("Ошибка получения геолокации:", error);
                }
            );
        } else {
            console.error("Геолокация не поддерживается браузером.");
        }
    }

    return (
        <Wrapper>
            <Button type='primaryIcon' onClick={handleClickBack}>
                <BackArrowIcon/>
            </Button>

            {window.innerWidth > 748 ? <SearchBar /> : null}
            
            <Button type='primaryIcon' onClick={handleClickLocation}>
                <CommunicationIcon/>
            </Button>
        </Wrapper>
    )
}

export default Header;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    max-width: 90%;
    padding: 50px 16px;
    user-select: none;
    pointer-events: none;

    @media (max-width: 758px) {
        max-width: 100%;
        padding: 16px;
    }
`
