import {
	GET_TODAY_ORDERS_COUNT_REQUEST,
	GET_TODAY_ORDERS_COUNT_SUCCESS,
} from "../actions/HeaderActions";

const initialState = {
	todayOrdersCount: 0,
	nextOrdersCount: 0
};

export function headerReducer(state = initialState, action) {
	switch (action.type) {
		case GET_TODAY_ORDERS_COUNT_REQUEST:
			return {
				...state,
				todayOrdersCount: 0,
                nextOrdersCount: 0
			};

		case GET_TODAY_ORDERS_COUNT_SUCCESS:
            return {
                ...state,
                todayOrdersCount: parseInt(action.payload.today.dayOrdersCount),
                nextOrdersCount: parseInt(action.payload.tomorrow.dayOrdersCount)
            };

		default:
			return state;
	}
}
