import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Welcome from './containers/welcome/Welcome';
import MapPage from './containers/mapPage/MapPage';
import Success from './containers/success/Success';
import useDeviceInfo from '../hooks/useDeviceInfo';

const InteractiveMapPromoModule = () => {
	useDeviceInfo();
	const { userCurrentCityId, device, orientation } = useSelector((state) => state.interactiveMapPromo.common);
	const { dataResult } = useSelector((state) => state.interactiveMapPromo.auth);

	console.log(device, orientation)

	if (device === 'mobile' && orientation === 'portrait') {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        
        window.addEventListener('resize', setVh);
        setVh();
    }

	return (
		<Wrapper>
			{!userCurrentCityId
				? <Welcome/>
				: dataResult
				? <Success/>
				: <MapPage/>
			}
		</Wrapper>
	)
}

export default InteractiveMapPromoModule;

const Wrapper = styled.div`
    position: relative;
	z-index: 999999;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: calc(var(--vh, 1vh) * 100);
`;
