import { request } from "../utils/request";

export const GET_TODAY_ORDERS_COUNT_REQUEST = "GET_TODAY_ORDERS_COUNT_REQUEST";
export const GET_TODAY_ORDERS_COUNT_SUCCESS = "GET_TODAY_ORDERS_COUNT_SUCCESS";

export const getTodayOrdersCount = () => async dispatch => {
	try {
		dispatch({
			type: GET_TODAY_ORDERS_COUNT_REQUEST
		});

		let response = await request("AppSOKitchenService", "CountOrdersForDate");

		dispatch({
			type: GET_TODAY_ORDERS_COUNT_SUCCESS,
			payload: response.data
		});
	} catch (err) {
		// Do nothing
	}
};
