export const NAME_COMPANY = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru') ? 'Дом 51' :
    window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru') ? 'Страус' :
        'Сытый';
export const PHONE_COMPANY = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru') ? '8 (8482) 37-14-01' :
    window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru') ? '+7 846-244-69-69' :
        '8 800 200-69-24';
export const TIME = "10:00:00";
export const API_ADDRESS = "https://api.menuforme.online/";
export const IS_51_OBED = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');

//Статусы заказа
export const ORDER_STATUS_NEW = 2;
export const ORDER_STATUS_PREPARING = 131072;
export const ORDER_STATUS_WAITING_FOR_DELIVERY = 1048576;
export const ORDER_STATUS_IS_DELIVERING = 8388608;
export const ORDER_STATUS_DELIVERED = 67108864;
export const ORDER_STATUS_CANCELED = 536870912;
export const ORDER_STATUS_COMPLETE = 1073741824;

//Компании которым запрещено делать заказы
export const LIST_USERS_STOP_ORDERS = [
    '6508',
    '5900',
    '1513',
    '5899',
    '6083',
    '5902',
    '5901',
];

//Стили
export const MAIN_COLOR = IS_51_OBED ? "#32CD32" : "#FF1744";
export const TXT_COLOR = "#37474F";
export const VERY_LIGHT_FONT = "font-weight: 200;\n    font-family: \"MuseoSans\"";
export const LIGHT_FONT = "font-weight: 300;\n    font-family: \"MuseoSans\"";
export const NORMAL_FONT = "font-weight: 400;\n    font-family: \"MuseoSans\"";
export const BOLD_FONT = "font-weight: 600;\n    font-family: \"MuseoSans\"";
