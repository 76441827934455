import React, {memo} from "react";
import s from './CustomMarker.module.css'

const CustomMarker = ({mapDragged}) => {
  return (
    (mapDragged === null) ? (
      <div className={`${s.markerWrapper}`}>
        <div className={s.markerCore}/>
        <div className={`${s.markerLeg}`}/>
      </div>
    ) : (
      <div className={mapDragged ? `${s.markerWrapper} ${s.markerWrapperAnimate}` : `${s.markerWrapper} ${s.markerWrapperAnimateBack}`}>
        <div className={s.markerCore}/>
        <div className={mapDragged ? `${s.markerLeg} ${s.markerLegAnimate}` : `${s.markerLeg} ${s.markerLegAnimateBack}`}/>
      </div>
    )

  )
}

export default memo(CustomMarker);
