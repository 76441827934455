import React from 'react';
import styled from 'styled-components';
import { SpinnerIcon } from './Icons';

const Spinner = () => {
    return (
        <SpinnerWrapper>
            <SpinnerIcon/>
        </SpinnerWrapper>
    );
};

export default Spinner;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;